import React from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getTermsAndConditionsSideDrawerOpen } from '@om/redux/selectors/raOptimal/review';
import './TermsAndConditionsDrawer.scss';
import { OmSideDrawer } from '@om/component-library-react';

const TermsAndConditionsDrawer = () => {
    const { drawerOpen } = useSelector(
        createStructuredSelector({
            drawerOpen: getTermsAndConditionsSideDrawerOpen
        })
    );

    return (
        <OmSideDrawer open={drawerOpen}>
            <div slot="side-drawer-content" className="terms-and-conditions">
                <h5 className="no-top-margin">
                    <strong>
                        Terms and Conditions
                    </strong>
                </h5>

                <section>
                    <p><small>
                        <strong>Please make sure that you read the following Terms and Conditions carefully before confirming your 
                            acceptance thereof:</strong>
                    </small></p>
                    <p><small>
                        <strong>About this terms and conditions</strong><br/>

                        This document explains the terms and conditions of the Max Optimal Retirement Annuity (RA) plan and explains 
                        the benefits payable. Once you have accepted these terms and conditions it will form part of the contract between 
                        Old Mutual Life Assurance Company (SA) Ltd (Old Mutual), you and SARAF. Once the plan has been issued Old 
                        Mutual will send you a set of documents with all the terms and conditions reflecting your membership with SARAF 
                        and important information related to the choices made by you throughout the application process. The full 
                        contract is made up of the plan Summary, Terms and Conditions, Application and declarations made by you.
                    </small></p>
                    <br/>
                    <p><small><strong>You will become a member of the South African Retirement Annuity Fund</strong></small></p>
                    <ul>
                        <li>By taking out this Retirement Annuity (RA) plan, you are automatically applying for membership of the 
                        South African Retirement Annuity Fund (SARAF). This is because your contributions will be invested for you by 
                        the Management Board of SARAF, in the RA plan. SARAF will be the official owner of the plan and will use its 
                        investment value to pay your retirement benefits to you (or your dependents) when the time comes.
                        </li>
                        <li>You become a member of SARAF once the trustees have accepted your application and your initial contribution 
                        has been received. As a SARAF member you will be bound by the Rules of the Fund. The Rules set out your 
                        entitlement to your retirement benefits as well as that of your dependents.
                        </li>
                    </ul>
                    <p><small><strong>How does Old Mutual fit into the picture?</strong></small></p>
                    <ul>
                        <li>Old Mutual underwrites all the benefits payable to members in terms of plans issued to SARAF. This means 
                        that for as long as the you as a member pay your contributions as agreed, Old Mutual will pay the benefits.
                        </li>
                        <li>Old Mutual is also the administrator of the Fund. This means that Old Mutual performs services on behalf of 
                        the SARAF board of trustees, such as collecting the contributions, providing members with an annual benefit statement 
                        and income tax certificates, call centre services and other online services.</li>
                    </ul>

                    
                    <p><small><strong>Choosing your underlying investment funds</strong></small></p>
                    <ul>
                        <li>You have the option to choose the underlying investment funds in which your contributions will be invested. 
                        Make sure that you read the “Fund Fact Sheet” of each fund that you choose so that you understand the investment 
                        risk of your fund/s and the fees that apply to it.
                        </li>
                        <li>Depending on your choice of investment funds Old Mutual may experience a delay (not of its own fault) when the 
                        value of the plan becomes payable. This sometimes happens due to the time it takes to sell the underlying assets in 
                        which your money is invested. We will unfortunately not accept liability for any such delay.</li>
                        <li>This will also apply in the case of instructions that we receive from you to switch between underlying funds. 
                        You can ask us for past investment performance of the investment options that you chose. You must however understand 
                        that past investment performance is not necessarily indicative of future performance.</li>
                    </ul>
                    
                    <p><small><strong>Optimal Booster</strong></small></p>
                    <ul>
                        <li>Your OPTIMAL BOOSTER gets paid into the RA plan at the fifth anniversary. This booster is 2.5% 
                        of the total value of your fund on that date.</li>
                        <li>Old Mutual will refund into the plan a portion of the fees that you have been charged before 
                        the Optimal Booster payment date.</li>
                        <li>The Optimal Booster will be paid provided that:
                            <ul className="no-gap">
                                <li>the RA plan is in force on the day that is indicated as the Optimal Booster payment date in your plan Summary, and
                                </li>
                                <li>you are still a member of SARAF.
                                </li>
                            </ul>
                        </li>
                        <li>The Optimal Booster will be paid proportionally into each investment fund in the plan.
                        </li>
                        <li>The Optimal Booster will be calculated to be a percentage of the fund value on the day indicated as 
                        the Optimal Booster payment date in the plan Summary. This percentage is reflected in the quote.
                        </li>
                        <li>Old Mutual will not remove the Optimal Booster once it has been paid into the plan. However, the value 
                        is not guaranteed as it will fluctuate with the value of each investment fund in the plan.
                        </li>
                    </ul>
                    
                    <p><small><strong>Monthly Booster</strong></small></p>
                    <ul>
                        <li>Monthly Boosters will be paid into the investment funds after the Optimal Booster date until the 
                        end of the premium paying term.
                        </li>
                        <li>Old Mutual will refund into the plan a portion of the monthly plan charge. This will be called a Monthly Booster.
                        </li>
                        <li>Old Mutual will refund into the plan a portion of the monthly plan charge. This will be called a Monthly Booster.
                        </li>
                        <li>The Monthly Booster will be paid proportionally into each investment fund in the plan starting from the 
                        month indicated in the plan Summary as the date of First Monthly Booster. The date in this month may change if 
                        you change the date of your premium payments.
                        </li>
                        <li>The Monthly Boosters will be calculated to be a percentage of the fund value on the date that they are to 
                        be paid. This percentage is reflected during the application process.
                        </li>
                        <li>Monthly Boosters will be paid monthly regardless of whether you are still paying premiums to SARAF or 
                        not, or whether any withdrawals have been made from the plan in terms of applicable legislation.
                        </li>
                        <li>Old Mutual will not remove the Monthly Booster once it has been paid into the plan. However, the value is 
                        not guaranteed as it will fluctuate with the value of each investment fund in the plan.
                        </li>
                    </ul>
                    
                    <p><small><strong>Important terms of the plan</strong></small></p>
                    <ul>
                        <li>During the application process you elected to pay your premiums for a term. This term is called the 
                        premium-paying term and will be shown in your plan summary, if you decide to proceed with this plan and 
                        your membership application is accepted by SARAF.
                        </li>
                        <li>At the end of the premium-paying term you will no longer have to pay premiums although SARAF has agreed 
                        that Old Mutual can continue to collect premiums by means of your chosen payment method at the end of the 
                        premium-paying term until you tell us to stop.
                        </li>
                        <li>The plan will not automatically come to an end or pay out a benefit at this time.
                        </li>
                        <li>The plan will continue until SARAF asks for the benefits, but you will have no obligation to pay further 
                        premiums. We may continue the plan in a similar product.
                        </li>
                        <li>This means that the value of the plan will stay invested in the investment funds until SARAF asks for the 
                        benefits.
                        </li>
                        <li>If you break your agreement to pay premiums or reduce the premiums, a transaction administration fee will 
                        be charged to cover the costs of administering the change to the plan.
                        </li>
                        <li>Please consult the charges detail provided in the application process, for more detail. These details will 
                        also be provided to you in the plan Summary.
                        </li>
                    </ul>

                    <p><small>The plan will become paid up if you do not pay premiums</small></p>
                    <ul>
                        <li>Premiums must be paid when they are due.
                        </li>
                        <li>If you do not pay a premium, you will have a period of grace in which to pay the premium.
                        </li>
                        <li>If a premium has not been paid within the grace period, the plan will become paid up from the date of the 
                        missed premium, according to Old Mutual’s then prevailing terms and conditions.
                        </li>
                    </ul>
                    
                    <p><small><strong>Reinstatement of the plan once it is paid up</strong></small></p>
                    <ul>
                        <li>You can apply to us to start paying premiums into the plan again after it has become paid up (in other 
                        words, reinstate the plan) provided this is done within one year of the plan becoming paid up.
                        </li>
                        <li>If you miss premiums in the first six months of the plan, you will have to repay all the missed premiums 
                        before you can reinstate the plan.
                        </li>
                        <li>You may miss a total of one year’s worth of premiums over the premium-paying term of the plan.
                        </li>
                        <li>We will allow you to reinstate the plan without your having to repay premiums if you have missed up to one 
                        year’s worth of premiums over the premium-paying term.
                        </li>
                        <li>If you miss more than one year’s worth of premiums over the premium-paying term, you will have to repay the 
                        premiums that are more than one year’s worth of premiums before you can reinstate the plan.
                        </li>
                        <li>If you miss more than one year’s worth of premiums in a row, you may not reinstate the plan.
                        </li>
                    </ul>
                    
                    <p><small><strong>You can apply to us to make changes to your premiums and your investment funds</strong></small></p>
                    <ul>
                        <li>You are allowed to increase or reduce your premiums at any time, except if we have started paying your 
                        premiums in terms of the Premium Protection Benefit (if that applies to you).
                        </li>
                        <li>Over a year, your premiums can be increased up to a maximum of R1 500 per month (or the equivalent for 
                        other payment frequencies) or 20% of your current premium, whichever is higher.
                        </li>
                        <li>Old Mutual may change these limits at its discretion.
                        </li>
                        <li>You are not allowed to reduce your premiums to less than the minimum premium limit that we set from time 
                        to time.
                        </li>
                    </ul>

                    <p><small>Subject to Old Mutual’s then prevailing terms and conditions, you may apply to:</small></p>
                    <ul>
                        <li>change the proportion of your premiums invested in an investment fund,
                        </li>
                        <li>pay other premiums into the plan,
                        </li>
                        <li>switch between investment funds.
                        </li>
                    </ul>

                    <p><small><strong>Nomination of beneficiaries</strong><br/>

                        You can nominate beneficiaries to receive your benefits under this plan after your death. You may make your 
                        applicable beneficiary nominations during the application process or you may provide us with beneficiary 
                        nominations and changes in writing after your plan is issued, in a form acceptable to us. 
                        The trustees of SARAF are obliged by law to identify and apportion the benefits payable on your death 
                        between your dependants and nominated beneficiaries at their discretion. This means that the persons who 
                        will receive payment may not necessarily be your nominated beneficiary or beneficiaries. 
                    </small></p>
                    <p><small>
                        <strong>Cession</strong><br/>

                        You are not allowed to cede the plan to another person. 
                    </small></p>
                    <p><small><strong>Charges</strong></small></p>
                    <ul>
                        <li>The charges currently payable in terms of the plan are shown in the FEES, CHARGES AND BOOSTERS section 
                        of the application and will be reflected in your plan Summary. 
                        </li>
                        <li>We can change these charges from time to time.  
                        </li>
                        <li>We have calculated the charges in such a way that they will reduce as the value of the plan increases. 
                        </li>
                        <li>You may qualify for a reduction in the plan charge if the plan meets our requirements.  This is also 
                        explained in the FEES, CHARGES AND BOOSTERS Section.
                        </li>
                    </ul>
                    
                    <p><small>
                        <strong>We can cancel the plan </strong><br/>

                        We will cancel the plan if the value of all investment funds that are in the plan, falls below the minimum 
                        limit that we set from time to time. If this happens we will not pay any value under that plan.<br/><br/>
                        We will cancel an investment fund if its value is not enough to pay the monthly charge that we levy against it.<br/><br/>
                        We may have to change the terms of the plan in certain circumstances<br/><br/>
                        If the law or a regulation is changed and it affects the plan, or if a court or a regulatory authority 
                        gives a different interpretation of an existing law or regulation, we have to change any affected terms of the plan.<br/><br/>
                        We buy assets and enter into agreements so that we can offer you investment funds in which you can choose to 
                        invest. These assets and agreements must comply with regulations, rules and laws made by the government, regulators 
                        and government agencies.  If any of these change and affect the return generated by the assets or agreements, 
                        we have the right to change the value of the investment fund that has been affected and also to change its charges.
                        This means that the return that you get on your chosen investment fund may be affected in the same way as the 
                        assets and agreements on which the investment fund is based. 
                    </small></p>
                    <p><small>
                        <strong>Withdrawal of investment funds</strong><br/>

                        If we withdraw an investment fund in which you are invested, we will send you a written notice asking you to 
                        choose another investment fund into which we must switch your investment or any future investments. If you do 
                        not make a nomination when you are asked to do so, we may switch the investment to an investment fund of our choice. 
                    </small></p>
                    <p><small>
                        <strong>Determinations or decisions by Old Mutual</strong><br/>

                        Wherever these terms and conditions state that a matter is subject to the determination or decision of 
                        Old Mutual, we will have full discretion as to the requirements on which the determination or decision will depend.
                    </small></p>
                    <p><small>
                        <strong>No advice provided to you</strong><br/>

                        By purchasing this plan online, you elected not to be represented by an intermediary or to receive financial 
                        advice. You can at any point after inception of the plan ask to be provided with financial advice. You may use 
                        an adviser of your own choice or ask that Old Mutual provide you with an adviser. You also confirm that the 
                        investment fund in this plan suits your financial needs. You may contact Old Mutual after the plan has been 
                        issued to enquire about further investment options.
                    </small></p>
                    <p><small>
                        <strong>You may cancel this application</strong><br/>

                        If you are not happy with the plan or change your mind after the purchase, you can cancel the transaction 
                        without having to give reasons within 31 days after receiving the plan.  You can do this by contacting our 
                        Customer Service Centre on 0860 50 60 70. If the value of your contribution reduced due to market movements, 
                        you will not receive a full refund of your contribution.<br/><br/>

                        <strong>Payment of your contributions</strong><br/>

                        Old Mutual (and the Fund) will not have any obligations in respect of this application until you have paid 
                        the first contribution into the Fund's bank account and Old Mutual has received it from the Fund. This will 
                        also determine the tax-year in which your contributions will be recognised for purposes of your annual tax 
                        certificate.<br/>
                        No interest or return of any type will be paid to you on funds paid into any account of Old Mutual during 
                        any period prior to the formal issuing of the plan.<br/>
                        The first contribution will be invested after it has been received by Old Mutual and after the formal issuing 
                        of the plan and at the first investment opportunity and at the price at that time.
                    </small></p>
                    <p><small>
                        <strong>Important Tax considerations</strong><br/>

                        In terms of South Africa's Tax laws, you can deduct the contributions that you make to an RA plan from your 
                        Income Tax, but only up to a certain limit.
                        As already indicated, you will be only be able to access a maximum of 1/3 of the proceeds when you retire 
                        (as a lump sum) but you will be taxed on this amount.
                        The rest of the proceeds must be used to purchase an income on which you will have to pay income tax.
                    </small></p>
                    <p><small>
                        <strong>Your contact details</strong><br/>

                        You must keep Old Mutual informed of your contact details so that we can trace you to make payment to you when due. 
                        If you or your beneficiaries and dependents do not claim the benefits of the plan, the Fund will keep it as long as 
                        the law requires while attempting to trace you or your beneficiaries and dependents.
                        The rules of SARAF allows the Fund to use the proceeds of the plan to cover the cost of tracing you or your 
                        beneficiaries and dependents. Tracing includes, but is not limited to, searching all Old Mutual internal customer
                        databases, as well as using external database and tracing agencies.
                        While the Fund is attempting to trace you or your beneficiaries and dependents the proceeds of the plan will remain 
                        in the assets that you elected. You will be entitled to any growth, but will also suffer any losses as a result of this.
                    </small></p>
                    <p><small>
                        <strong>PROTECTION OF YOUR PERSONAL INFORMATION (PPI)</strong><br/>

                        The Old Mutual Group would like to offer you ongoing financial services and may use your personal information 
                        to provide you with information about products or services that may be suitable to meet your financial needs.  
                        Please sms your ID number to 30994 if you would prefer not to receive such information and/or financial services. 
                        We may use your information or obtain information about you for the following purposes:
                    </small></p>
                    <ul className="no-gap">
                        <li>Underwriting
                        </li>
                        <li>Assessment and processing of claims
                        </li>
                        <li>Credit searches and/or verification of personal information
                        </li>
                        <li>Claims checks 
                        </li>
                        <li>Tracing beneficiaries
                        </li>
                        <li>Fraud prevention and detection
                        </li>
                        <li>Market research and statistical analysis
                        </li>
                        <li>Audit & record keeping purposes
                        </li>
                        <li>Compliance with legal & regulatory requirements
                        </li>
                        <li>Verifying your identity
                        </li>
                        <li>Sharing information with service providers we engage to process such information on our behalf or 
                        who render services to us.  These service providers may be abroad, but we will not share your information with 
                        them unless we are satisfied that they have adequate security measures in place to protect your personal information.
                        </li>
                    </ul>
   
                    <p><small>You may access your personal information that we hold and may also request us to correct any errors or to delete 
                        this information. In certain cases you have the right to object to the processing of your personal information.
                    </small></p>
                    <p><small>
                        <strong>Complaints</strong><br/>

                        Old Mutual provides a complaint resolution process to address any 
                        dissatisfaction you may have with your transaction. You can: 
                    </small></p>
                    <ul className="no-gap">
                        <li>write to the OMSTA Complaints Management Department, PO Box 201, Mutualpark 7451
                        </li>
                        <li>fax +27 21 509 0506
                        </li>
                        <li>email complaintadmin@oldmutual.com
                        </li>
                        <li>Contact our Compliance Department by phoning the Customer Service Centre on 0860 50 6070 
                        </li>
                    </ul>

                    <p><small><strong>If your dispute is not resolved, you have the right to:</strong></small></p>
                    <ul className="no-gap">
                        <li>contact the Office of Internal Arbitration, Old Mutual, PO Box 80, Mutualpark 7451
                        </li>
                        <li>fax +27 21 504 7700
                        </li>
                        <li>email arbitrator@oldmutual.com
                        </li>
                    </ul>

                    <p><small>If your complaint is still not dealt with, you have the right to:</small></p>
                    <ul className="no-gap">
                        <li>contact the Ombudsman for Long-term Insurance, Private Bag X45, Claremont 7735
                        </li>
                        <li>email info@ombud.co.za
                        </li>
                    </ul>
                    
                    <p><small><strong>For complaints about any advice given to you, you can:</strong></small></p>
                    <ul className="no-gap">
                        <li>contact the FAIS Ombud, PO Box 74571, Lynwood Ridge 0040
                        </li>
                        <li>email info@faisombud.co.za
                        </li>
                    </ul>

                    <p><small>You also have the right to complain to the Information Regulator, whose contact details are:</small></p>
                    <ul className="no-gap">
                        <li>http://www.justice.gov.za/inforeg/index.html
                        </li>
                        <li>Tel: 012 406 4818
                        </li>
                        <li>Fax: 086 500 3351
                        </li>
                        <li>Email: inforeg@justice.gov.za
                        </li>
                    </ul>
                    <p><small>
                        To view our full privacy notice and to exercise your preferences, please visit our website on www.oldmutual.co.za<br/><br/>
            
                        <strong>The fees that you will pay Old Mutual</strong><br/>
                        During this application process the fees that Old Mutual will deduct from your plan were displayed under the heading 
                        FEES, CHARGES AND BOOSTERS. By accepting these Terms and Conditions you confirm that you read and accept the fees as disclosed.
                        <br/><br/>
                        You were also provided with a summary of the impact of the fees and charges on the value of the plan. This 
                        is an illustrative sample only, and was displayed under the heading EFFECTIVE ANNUAL COST. 
                        It is important that you read the summary. The full EFFECTIVE ANNUAL COST document that is applicable to your
                        own policy will be provided to you once the plan is issued.
                    </small></p>
                    <p><small>
                        <strong>You will receive your plan contract per email</strong><br/>

                        After accepting these Terms and Conditions and submitting the application and, provided all requirements are 
                        satisfied, Old Mutual will issue the final contract and send it to you by email. This may take a few days, 
                        especially if we need to verify your information.
                    </small></p>
                </section>
            </div>
        </OmSideDrawer>
    );
};

export default TermsAndConditionsDrawer;
