import React from 'react';
import { OmModalLoader } from '@om/component-library-react';

const ApplicationModalLoader = ({
    text,
    verticalAlign,
    open
}) => {
    return (
        <OmModalLoader
            loaderText={text}
            verticalAlignMiddle={verticalAlign}
            open={open}
        />
    );
};

export default ApplicationModalLoader;