import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ApplicationBeneficiarySplitList from '../../../../components/applications/ApplicationBeneficiarySplitList';

import { getBeneficiaryList } from '@om/redux/selectors/raOptimal/beneficiaries';
import {
    setSplitPercentageValue,
    selectEvenSplit,
    deselectEvenSplit,
    discardEvenSplit,
} from '@om/redux/actions/raOptimal/beneficiariesSplit';
import {
    getFormStatusIsValid,
    getIsEvenSplit,
    getSplitError,
    getSplitFieldArray,
    getTotalPercentage,
} from '@om/redux/selectors/raOptimal/beneficiarySplit';

const BeneficiariesSplitSection = () => {
    const dispatch = useDispatch();
    const {
        beneficiaryList,
        formStatusIsValid,
        splitFieldArray,
        totalPercentage,
        splitError,
        isEvenSplit,
    } = useSelector(
        createStructuredSelector({
            beneficiaryList: getBeneficiaryList,
            formStatusIsValid: getFormStatusIsValid,
            splitFieldArray: getSplitFieldArray,
            totalPercentage: getTotalPercentage,
            splitError: getSplitError,
            isEvenSplit: getIsEvenSplit,
        })
    );

    const toggleEvenSplit = () => {
        if (isEvenSplit) {
            dispatch(deselectEvenSplit());
            dispatch(discardEvenSplit());
            return;
        }
        dispatch(selectEvenSplit());
    };

    return (
        <div>
            <h5 className="no-top-margin">
                Split your <span className="medium"> investment between your beneficiaries </span>
            </h5>
            <ApplicationBeneficiarySplitList 
                beneficiaryList={beneficiaryList}
                beneficiaryListStatus={formStatusIsValid ? 'valid' : 'error'}
                totalPercentage={totalPercentage}
                splitError={splitError}
                isEvenSplit={isEvenSplit}
                splitFields={splitFieldArray}
                checkBoxChangedHandler={() => toggleEvenSplit()}
                inputPersistHandler={(detail, index) => dispatch(setSplitPercentageValue(detail, index))}
            />
        </div>
    );
};

export default BeneficiariesSplitSection;

