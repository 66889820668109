import React from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getReplacementPolicySideDrawerOpen } from '@om/redux/selectors/raOptimal/review';
import { OmSideDrawer } from '@om/component-library-react';

const ReplacementPolicyDrawer = () => {
    const { drawerOpen } = useSelector(
        createStructuredSelector({
            drawerOpen: getReplacementPolicySideDrawerOpen
        })
    );

    return (
        <OmSideDrawer open={drawerOpen}>
            <div slot="side-drawer-content" className="replacement-policy">
                <h5 className="no-top-margin">
                    <strong>
                        Replacement Policy
                    </strong>
                </h5>

                <section>
                    <p><small>
                            - Certain charges may be incurred on the replacement policy, resulting in these charges and fees being paid twice,
                            e.g. initial charges on the existing financial product and once again on the new product. 
                    </small></p>
                    <p><small>
                            - Your new product may not have the same investment performance guarentees as the existing product.
                    </small></p>
                    <p><small>
                            - If you are cancelling a risk cover product to take out this specific product, you might not qualify
                            for the same risk cover when you want to take it out again. 
                    </small></p>
                    <p><small>
                            - The surrender value or paid-up value of the replaced product may be as low as 65% of the value 
                            before the change, and could be even less than premiums paid in since unrecovered initial expenses
                            may first be deducted. Check what charges you will be paying if you end the existing product and 
                            see whether the advantages of the new product will make up for any such charges. 
                    </small></p>
                    <p><small>
                            - The investment risk under the new product may be higher. Remember that the past performance of a fund or asset manager
                            of a fund is not necessarily an indication of future performance. 
                    </small></p>
                </section>
            </div>
        </OmSideDrawer>
    );
};

export default ReplacementPolicyDrawer;
