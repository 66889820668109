import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleReadMoreDrawer } from '@om/redux/actions/raOptimal/beneficiaries';

const BeneficiariesLandingSection = () => {
    const dispatch = useDispatch();

    return (
        <div>
            <h5 className="no-top-margin">
                Why you should <span className="medium"> add a beneficiary </span>
            </h5>

            <ul className="trustees">
                <li>
                    In the unfortunate event that you pass away before you retire,
                    you may nominate one or more individuals as beneficiaries to the value of your funds.
                </li>
                <li>
                    However, trustees of the retirement fund will have the final say over who will receive the funds.
                    <a
                        className="highlight-read-more medium"
                        onClick={() => dispatch(toggleReadMoreDrawer())}>
                        Read more
                    </a>
                </li>
            </ul>
        </div> 
    );
};

export default BeneficiariesLandingSection;

