import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getIsCvsVerified } from '@om/redux/selectors/raOptimal/investmentSetup';
import {
    setPostalAddressMethodValue,
    setPostalAddressSameValue,
    setTitleValue,
    setMiddleNameValue,
    setEmailValue,
    setIdValue,
    setMaritalStatusValue,
    setNationalityValue,
    setIncomeTaxValue,
    setCountryOfBirthValue,
    setComplexValue,
    setStreetAddressValue,
    setSuburbValue,
    setCityValue,
    setPostalCodeValue,
    setCountryValue,
    setPoBoxValue,
    setPoPostOfficeValue, 
    setPoPostalCodeValue,
    setPoCountryValue,
    setPrivateBagValue,
    setPrivatePostOfficeValue,
    setPrivatePostalCodeValue, 
    setPrivateCountryValue, 
    setPostalComplexValue,
    setPostalStreetAddressValue, 
    setPostalSuburbValue, 
    setPostalCityValue, 
    setPostalPostalCodeValue, 
    setPostalCountryValue,
} from '@om/redux/actions/raOptimal/personalDetails';
import {
    getTitle,
    getMiddleName,
    getEmail,
    getId,
    getMaritalStatus,
    getNationality,
    getIncomeTax,
    getCountryOfBirth,
    getComplex,
    getStreetAddress,
    getSuburb,
    getCity,
    getPostalCode,
    getCountry,
    getPoBox,
    getPoPostOffice, 
    getPoPostalCode,
    getPoCountry,
    getPrivateBag,
    getPrivatePostOffice,
    getPrivatePostalCode, 
    getPrivateCountry, 
    getPostalComplex,
    getPostalStreetAddress, 
    getPostalSuburb, 
    getPostalCity, 
    getPostalPostalCode, 
    getPostalCountry,
    getPostalAddressSame,
    getPostalAddressMethod,
    getStreetAddressPostalMethod,
    getPoBoxPostalMethod,
    getPrivateBagPostalMethod
} from '@om/redux/selectors/raOptimal/personalDetails';
import {
    setNameValue,
    setSurnameValue,
    setMobileValue,
    setDisplayName
} from '@om/redux/actions/raOptimal/captureDetails';
import {
    getName,
    getSurname,
    getMobile,
    getIncludeDropoffDetails,
    getDisplayName
} from '@om/redux/selectors/raOptimal/captureDetails';
import {
    getReferenceTitles, 
    getReferenceMaritalStatuses,
    getReferenceNationalities,
    getReferencePostalMethods,
    getReferenceCountryDialingCodes
} from '@om/redux/selectors/raOptimal/referenceData';
import { DEFAULT_VALIDATION_DELAY } from '../../config';
import {
    OmFormDropdownFieldWrapper,
    OmFormInputFieldWrapper,
    OmRaPersonalDetails,
    OmFormError,
    OmRadioChipButtonGroup
} from '@om/component-library-react';

const PersonalDetailsPage = () => {
    const dispatch = useDispatch();
    const {
        postalAddressSame,
        postalAddressMethod,
        dropoffDetailsIncluded,

        title,
        name,
        middleName,
        surname,
        mobile,
        email,
        maritalStatus,
        countryOfBirth,
        nationality,
        id,
        incomeTax,

        complex,
        streetAddress,
        suburb,
        city,
        postalCode,
        country,

        postalComplex,
        postalStreetAddress,
        postalSuburb,
        postalCity,
        postalPostalCode,
        postalCountry,

        poBox,
        poPostOffice,
        poPostalCode,
        poCountry,

        privateBag,
        privatePostOffice,
        privatePostalCode,
        privateCountry,

        referenceTitles,
        referenceMaritalStatuses,
        referenceNationalities,
        referencePostalMethods,

        cvsIsVerified,
        displayName,

        streetAddressPostalMethod,
        poBoxPostalMethod,
        privateBagPostalMethod
    } = useSelector(
        createStructuredSelector({
            postalAddressSame: getPostalAddressSame,
            postalAddressMethod: getPostalAddressMethod, 
            dropoffDetailsIncluded: getIncludeDropoffDetails,

            title: getTitle,
            name: getName,
            middleName: getMiddleName,
            surname: getSurname,
            mobile: getMobile, 
            email: getEmail,
            maritalStatus: getMaritalStatus,
            countryOfBirth: getCountryOfBirth,
            nationality: getNationality,
            id: getId,
            incomeTax: getIncomeTax, 

            complex: getComplex,
            streetAddress: getStreetAddress,
            suburb: getSuburb, 
            city: getCity,
            postalCode: getPostalCode,
            country: getCountry,

            postalComplex: getPostalComplex,
            postalStreetAddress: getPostalStreetAddress,
            postalSuburb: getPostalSuburb, 
            postalCity: getPostalCity,
            postalPostalCode: getPostalPostalCode,
            postalCountry: getPostalCountry,

            poBox: getPoBox,
            poPostOffice: getPoPostOffice,
            poPostalCode: getPoPostalCode,
            poCountry: getPoCountry,

            privateBag: getPrivateBag,
            privatePostOffice: getPrivatePostOffice,
            privatePostalCode: getPrivatePostalCode,
            privateCountry: getPrivateCountry,

            referenceTitles: getReferenceTitles, 
            referenceMaritalStatuses: getReferenceMaritalStatuses,
            referenceNationalities: getReferenceNationalities,
            referencePostalMethods: getReferencePostalMethods,
            referenceCountryDialingCodes: getReferenceCountryDialingCodes,

            cvsIsVerified: getIsCvsVerified,
            displayName: getDisplayName,

            streetAddressPostalMethod: getStreetAddressPostalMethod,
            poBoxPostalMethod: getPoBoxPostalMethod,
            privateBagPostalMethod: getPrivateBagPostalMethod
        })
    );

    const nameUpdateHandler = () => {
        if (name.status === 'valid')
            dispatch(setDisplayName(name.value))
    };
    const updatePostalAddressMethod = (id) => {
        if (id !== postalAddressMethod.value) {
            dispatch(setPostalAddressMethodValue(id));
        }
    };
    const radioChipButtonListItems = [
        { value: true, description: 'YES' },
        { value: false, description: 'NO' }
    ];

    const postalAddressSelected = [streetAddressPostalMethod.code, privateBagPostalMethod.code, poBoxPostalMethod.code].includes(postalAddressMethod.value);
    const postalAddressSameValue = postalAddressSelected ? postalAddressMethod.value : null;

    return (
        <OmRaPersonalDetails onNameUpdated={() => nameUpdateHandler()}>
            <h4 slot="heading" className="sessioncamhidetext">
                {dropoffDetailsIncluded ? `Good progress, ` + displayName + `.` : `Good progress.`} <strong> Now let's get formal</strong>
            </h4>
            <p slot="subheading">
                We need the following information to set up your contract.
            </p>
            <div slot="form">
                <section className="form-section">
                    <h6 className="medium">Personal details</h6>
                    <OmFormDropdownFieldWrapper
                        useNativeMobileDropdown
                        id="title"
                        required
                        formId="personalDetailsForm"
                        placeholder="Title"
                        name="title"
                        align="left"
                        value={title.value}
                        state={title.status}
                        errorMessage={title.error}
                        errorMessagePopupAlignment="left"
                        options={referenceTitles &&
                            JSON.stringify(referenceTitles.map(option => ({ value: option.code, label: option.description })))
                        }
                        onOnSelect={event => dispatch(setTitleValue(event.detail.id)) }
                    />
                    <OmFormInputFieldWrapper
                        isSensitive
                        id="name"
                        placeholder="First name"
                        required
                        size="full"
                        form-id="personalDetailsForm"
                        name="name"
                        align="left"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={name.value}
                        state={cvsIsVerified ? 'disabled' : name.status}
                        errorMessage={name.error}
                        onPersist={event => dispatch(setNameValue(event.detail))}
                    />
                    <OmFormInputFieldWrapper
                        isSensitive
                        id="middleName"
                        placeholder="Middle name(s)"
                        required
                        size="full"
                        form-id="personalDetailsForm"
                        name="middleName"
                        align="left"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={middleName.value}
                        state={middleName.status}
                        errorMessage={middleName.error}
                        onPersist={event => dispatch(setMiddleNameValue(event.detail))}
                    />
                    <OmFormInputFieldWrapper
                        isSensitive
                        id="surname"
                        placeholder="Surname"
                        required
                        size="full"
                        form-id="personalDetailsForm"
                        name="surname"
                        align="left"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={surname.value}
                        state={surname.status}
                        errorMessage={surname.error}
                        onPersist={event => dispatch(setSurnameValue(event.detail))}
                    />
                    <OmFormInputFieldWrapper
                        isSensitive
                        id="id"
                        placeholder="South African ID number"
                        required
                        size="full"
                        form-id="personalDetailsForm"
                        name="id"
                        align="left"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={id.value}
                        state={cvsIsVerified ? 'disabled' : id.status}
                        errorMessage={id.error}
                        onPersist={event => dispatch(setIdValue(event.detail))}
                    />
                    <OmFormDropdownFieldWrapper
                        useNativeMobileDropdown
                        id="countryOfBirth"
                        required
                        filterable
                        size="full"
                        form-id="personalDetailsForm"
                        placeholder="Country of birth"
                        name="countryOfBirth"
                        align="left"
                        errorMessagePopupAlignment="left"
                        value={countryOfBirth.value}
                        state={countryOfBirth.status}
                        errorMessage={countryOfBirth.error}
                        options={referenceNationalities &&
                            JSON.stringify(referenceNationalities.map(option => ({ value: option.code, label: option.description })))
                        }
                        onOnSelect={event => dispatch(setCountryOfBirthValue(event.detail.id)) }
                    />
                    <OmFormDropdownFieldWrapper
                        use-native-mobile-dropdown
                        id="nationality"
                        required
                        filterable
                        size="full"
                        form-id="personalDetailsForm"
                        placeholder="Nationality"
                        name="nationality"
                        align="left"
                        errorMessagePopupAlignment="left"
                        value={nationality.value}
                        state={nationality.status}
                        errorMessage={nationality.error}
                        options={referenceNationalities &&
                            JSON.stringify(referenceNationalities.map(option => ({ value: option.code, label: option.description })))
                        }
                        onOnSelect={event => dispatch(setNationalityValue(event.detail.id)) }
                    />
                    <OmFormDropdownFieldWrapper
                        useNativeMobileDropdown
                        id="maritalStatus"
                        required
                        form-id="personalDetailsForm"
                        placeholder="Marital status"
                        name="maritalStatus"
                        align="left"
                        errorMessagePopupAlignment="left"
                        value={maritalStatus.value}
                        state={maritalStatus.status}
                        errorMessage={maritalStatus.error}
                        options={referenceMaritalStatuses &&
                            JSON.stringify(referenceMaritalStatuses.map(option => ({ value: option.code, label: option.description })))
                        }
                        onOnSelect={event => dispatch(setMaritalStatusValue(event.detail.id)) }
                    />
                    <OmFormInputFieldWrapper
                        isSensitive
                        id="incomeTax"
                        placeholder="Income tax number (optional)"
                        required
                        size="full"
                        form-id="personalDetailsForm"
                        name="incomeTax"
                        align="left"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={incomeTax.value}
                        state={incomeTax.status}
                        errorMessage={incomeTax.error}
                        onPersist={event => dispatch(setIncomeTaxValue(event.detail))}
                    />
                    
                    <h6 className="medium">Contact details</h6>
                    <OmFormInputFieldWrapper
                        isSensitive
                        id="mobile"
                        placeholder="Contact Number"
                        required
                        size="full"
                        form-id="personalDetailsForm"
                        name="phoneNumber"
                        align="left"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={mobile.value}
                        state={mobile.status}
                        errorMessage={mobile.error}
                        onPersist={event => dispatch(setMobileValue(event.detail))}
                    />
                    <OmFormInputFieldWrapper
                        isSensitive
                        id="email"
                        placeholder="Email"
                        required
                        size="full"
                        form-id="personalDetailsForm"
                        name="email"
                        align="left"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={email.value}
                        state={email.status}
                        errorMessage={email.error}
                        onPersist={event => dispatch(setEmailValue(event.detail))}
                    />
                    
                    <h6 className="medium">Residential address details</h6>
                    <OmFormInputFieldWrapper
                        isSensitive
                        id="complex"
                        placeholder="Complex/building"
                        required
                        size="full"
                        form-id="personalDetailsForm"
                        name="complex"
                        align="left"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={complex.value}
                        state={complex.status}
                        errorMessage={complex.error}
                        onPersist={event => dispatch(setComplexValue(event.detail))}
                    />
                    <OmFormInputFieldWrapper
                        isSensitive
                        id="streetAddress"
                        placeholder="Street address"
                        required
                        size="full"
                        form-id="personalDetailsForm"
                        name="streetAddress"
                        align="left"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={streetAddress.value}
                        state={streetAddress.status}
                        errorMessage={streetAddress.error}
                        onPersist={event => dispatch(setStreetAddressValue(event.detail))}
                    />
                    <OmFormInputFieldWrapper
                        isSensitive
                        id="suburb"
                        placeholder="Suburb"
                        required
                        size="full"
                        form-id="personalDetailsForm"
                        name="suburb"
                        align="left"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={suburb.value}
                        state={suburb.status}
                        errorMessage={suburb.error}
                        onPersist={event => dispatch(setSuburbValue(event.detail))}
                    />
                    <OmFormInputFieldWrapper
                        isSensitive
                        placeholder="City"
                        required
                        size="full"
                        form-id="personalDetailsForm"
                        name="city"
                        align="left"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={city.value}
                        state={city.status}
                        errorMessage={city.error}
                        onPersist={event => dispatch(setCityValue(event.detail))}
                    />
                    <OmFormInputFieldWrapper
                        isSensitive
                        id="postalCode"
                        placeholder="Postal code"
                        required
                        size="full"
                        form-id="personalDetailsForm"
                        name="postalCode"
                        align="left"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={postalCode.value}
                        state={postalCode.status}
                        errorMessage={postalCode.error}
                        onPersist={event => dispatch(setPostalCodeValue(event.detail))}
                    />
                    <OmFormDropdownFieldWrapper
                        use-native-mobile-dropdown
                        id="country"
                        required
                        filterable
                        size="full"
                        form-id="personalDetailsForm"
                        placeholder="Country"
                        name="country"
                        align="left"
                        value={country.value}
                        state={country.status}
                        errorMessage={country.error}
                        options={referenceNationalities &&
                            JSON.stringify(referenceNationalities.map(option => ({ value: option.code, label: option.description })))
                        }
                        onOnSelect={event => dispatch(setCountryValue(event.detail.id)) }
                    />
                    <p className="form-text">Is your postal address the same?</p>
                    
                    <OmRadioChipButtonGroup
                        id="postalAddressSame"
                        value={postalAddressSame.value}
                        groupName="postalAddressSameGroup"
                        onOnSelection={event => dispatch(setPostalAddressSameValue(event.detail === 'true'))}
                    >
                        <ul slot="list-items">
                            { radioChipButtonListItems.map((listItem, index) => {
                                return (
                                    <li value={listItem.value} key={index}>
                                        {listItem.description}
                                    </li>
                                )
                            })}
                        </ul>
                    </OmRadioChipButtonGroup>
                    <OmFormError message={postalAddressSame.error}></OmFormError>
    
                    { postalAddressSame.value === false &&
                        <div>
                            <p className="form-text">Select your preferred postal method</p>
                            <OmFormDropdownFieldWrapper
                                useNativeMobileDropdown
                                id="postalAddressMethod"
                                required
                                size="full"
                                form-id="personalDetailsForm"
                                name="postalAddressMethod"
                                align="left"
                                errorMessagePopupAlignment="left"
                                value={postalAddressSameValue}
                                state={postalAddressMethod.status}
                                errorMessage={postalAddressMethod.error}
                                options={referencePostalMethods &&
                                    JSON.stringify(referencePostalMethods.map(option => ({ value: option.code, label: option.description })))
                                }
                                onOnSelect={(event) => updatePostalAddressMethod(event.detail.id)}
                            />

                            { postalAddressSelected && 
                                <p className="form-text">Please complete the following:</p>
                            }
                            
                            { postalAddressMethod.value === streetAddressPostalMethod.code && 
                                <div>
                                    <OmFormInputFieldWrapper
                                        isSensitive
                                        id="postalComplex"
                                        placeholder="Complex/building"
                                        required
                                        size="full"
                                        form-id="personalDetailsForm"
                                        name="postalComplex"
                                        align="left"
                                        validationDelay={DEFAULT_VALIDATION_DELAY}
                                        value={postalComplex.value}
                                        state={postalComplex.status}
                                        errorMessage={postalComplex.error}
                                        onPersist={event => dispatch(setPostalComplexValue(event.detail))}
                                    />
                                    <OmFormInputFieldWrapper
                                        isSensitive
                                        id="postalStreetAddress"
                                        placeholder="Street address"
                                        required
                                        size="full"
                                        form-id="personalDetailsForm"
                                        name="postalStreetAddress"
                                        align="left"
                                        validationDelay={DEFAULT_VALIDATION_DELAY}
                                        value={postalStreetAddress.value}
                                        state={postalStreetAddress.status}
                                        errorMessage={postalStreetAddress.error}
                                        onPersist={event => dispatch(setPostalStreetAddressValue(event.detail))}
                                    />
                                    <OmFormInputFieldWrapper
                                        isSensitive
                                        id="postalSuburb"
                                        placeholder="Suburb"
                                        required
                                        size="full"
                                        form-id="personalDetailsForm"
                                        name="postalSuburb"
                                        align="left"
                                        validationDelay={DEFAULT_VALIDATION_DELAY}
                                        value={postalSuburb.value}
                                        state={postalSuburb.status}
                                        errorMessage={postalSuburb.error}
                                        onPersist={event => dispatch(setPostalSuburbValue(event.detail))}
                                    />
                                    <OmFormInputFieldWrapper
                                        id="postalCity"
                                        placeholder="City"
                                        required
                                        size="full"
                                        form-id="personalDetailsForm"
                                        name="postalCity"
                                        align="left"
                                        validationDelay={DEFAULT_VALIDATION_DELAY}
                                        value={postalCity.value}
                                        state={postalCity.status}
                                        errorMessage={postalCity.error}
                                        onPersist={event => dispatch(setPostalCityValue(event.detail))}
                                    />
                                    <OmFormInputFieldWrapper
                                        isSensitive
                                        id="postalPostalCode"
                                        placeholder="Postal code"
                                        required
                                        size="full"
                                        form-id="personalDetailsForm"
                                        name="postalPostalCode"
                                        align="left"
                                        validationDelay={DEFAULT_VALIDATION_DELAY}
                                        value={postalPostalCode.value}
                                        state={postalPostalCode.status}
                                        errorMessage={postalPostalCode.error}
                                        onPersist={event => dispatch(setPostalPostalCodeValue(event.detail))}
                                    />
                                    <OmFormDropdownFieldWrapper
                                        id="postalCountry"
                                        useNativeMobileDropdown
                                        required
                                        filterable
                                        size="full"
                                        form-id="personalDetailsForm"
                                        placeholder="Country"
                                        name="postalCountry"
                                        align="left"
                                        errorMessagePopupAlignment="left"
                                        value={postalCountry.value}
                                        state={postalCountry.status}
                                        errorMessage={postalCountry.error}
                                        options={referenceNationalities &&
                                            JSON.stringify(referenceNationalities.map(option => ({ value: option.code, label: option.description })))
                                        }
                                        onOnSelect={event => dispatch(setPostalCountryValue(event.detail.id)) }
                                    />
                                </div>
                            }

                            { postalAddressMethod.value === poBoxPostalMethod.code &&
                                <div>
                                    <OmFormInputFieldWrapper
                                        isSensitive
                                        id="poBox"
                                        placeholder="PO box"
                                        required
                                        size="full"
                                        form-id="personalDetailsForm"
                                        name="poBox"
                                        align="left"
                                        validationDelay={DEFAULT_VALIDATION_DELAY}
                                        value={poBox.value}
                                        state={poBox.status}
                                        errorMessage={poBox.error}
                                        onPersist={event => dispatch(setPoBoxValue(event.detail))}
                                    />
                                    <OmFormInputFieldWrapper
                                        isSensitive
                                        id="poPostOffice"
                                        placeholder="Post office"
                                        required
                                        size="full"
                                        form-id="personalDetailsForm"
                                        name="poPostOffice"
                                        align="left"
                                        validationDelay={DEFAULT_VALIDATION_DELAY}
                                        value={poPostOffice.value}
                                        state={poPostOffice.status}
                                        errorMessage={poPostOffice.error}
                                        onPersist={event => dispatch(setPoPostOfficeValue(event.detail))}
                                    />
                                    <OmFormInputFieldWrapper
                                        isSensitive
                                        id="poPostalCode"
                                        placeholder="Postal code"
                                        required
                                        size="full"
                                        form-id="personalDetailsForm"
                                        name="poPostalCode"
                                        align="left"
                                        validationDelay={DEFAULT_VALIDATION_DELAY}
                                        value={poPostalCode.value}
                                        state={poPostalCode.status}
                                        errorMessage={poPostalCode.error}
                                        onPersist={event => dispatch(setPoPostalCodeValue(event.detail))}
                                    />
                                    <OmFormDropdownFieldWrapper
                                        id="poCountry"
                                        useNativeMobileDropdown
                                        required
                                        filterable
                                        size="full"
                                        form-id="personalDetailsForm"
                                        placeholder="Country"
                                        name="poCountry"
                                        align="left"
                                        errorMessagePopupAlignment="left"
                                        value={poCountry.value}
                                        state={poCountry.status}
                                        errorMessage={poCountry.error}
                                        options={referenceNationalities &&
                                            JSON.stringify(referenceNationalities.map(option => ({ value: option.code, label: option.description })))
                                        }
                                        onOnSelect={event => dispatch(setPoCountryValue(event.detail.id)) }
                                    />
                                </div>
                            }

                            { postalAddressMethod.value === privateBagPostalMethod.code && 
                                <div>
                                    <OmFormInputFieldWrapper
                                        isSensitive
                                        id="privateBag"
                                        placeholder="Private bag number"
                                        required
                                        size="full"
                                        form-id="personalDetailsForm"
                                        name="privateBag"
                                        align="left"
                                        validationDelay={DEFAULT_VALIDATION_DELAY}
                                        value={privateBag.value}
                                        state={privateBag.status}
                                        errorMessage={privateBag.error}
                                        onPersist={event => dispatch(setPrivateBagValue(event.detail))}
                                    />
                                    <OmFormInputFieldWrapper
                                        isSensitive
                                        id="privatePostOffice"
                                        placeholder="Post office"
                                        required
                                        size="full"
                                        form-id="personalDetailsForm"
                                        name="privatePostOffice"
                                        align="left"
                                        validationDelay={DEFAULT_VALIDATION_DELAY}
                                        value={privatePostOffice.value}
                                        state={privatePostOffice.status}
                                        errorMessage={privatePostOffice.error}
                                        onPersist={event => dispatch(setPrivatePostOfficeValue(event.detail))}
                                    />
                                    <OmFormInputFieldWrapper
                                        isSensitive
                                        id="privatePostalCode"
                                        placeholder="Postal code"
                                        required
                                        size="full"
                                        form-id="personalDetailsForm"
                                        name="privatePostalCode"
                                        align="left"
                                        validationDelay={DEFAULT_VALIDATION_DELAY}
                                        value={privatePostalCode.value}
                                        state={privatePostalCode.status}
                                        errorMessage={privatePostalCode.error}
                                        onPersist={event => dispatch(setPrivatePostalCodeValue(event.detail))}
                                    />
                                    <OmFormDropdownFieldWrapper
                                        id="privateCountry"
                                        useNativeMobileDropdown
                                        required
                                        filterable
                                        size="full"
                                        form-id="personalDetailsForm"
                                        placeholder="Country"
                                        name="privateCountry"
                                        align="left"
                                        errorMessagePopupAlignment="left"
                                        value={privateCountry.value}
                                        state={privateCountry.status}
                                        errorMessage={privateCountry.error}
                                        options={referenceNationalities &&
                                            JSON.stringify(referenceNationalities.map(option => ({ value: option.code, label: option.description })))
                                        }
                                        onOnSelect={event => dispatch(setPrivateCountryValue(event.detail.id)) }
                                    />
                                </div>
                            }
                        </div>
                    }

                </section>
            </div>
        </OmRaPersonalDetails>
    );
};

export default PersonalDetailsPage;

