import React from 'react';
import OmButton from '../forms/OmButton';
import { OmModalError  } from '@om/component-library-react';

const ApplicationErrorModal = ({
    text,
    heading,
    open,
    onClose,
    buttonText
}) => {
    return (
        <OmModalError
            errorHeaderText={heading}
            errorText={text}
            open={open}
        >
            <div slot="go-home">
                <OmButton
                    type="primary"
                    size="large"
                    text={buttonText}
                    onClick={() => onClose()}
                />
            </div>
        </OmModalError>
    );
};

export default ApplicationErrorModal;
