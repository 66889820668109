import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { setRemoveId, deleteBeneficiary } from '@om/redux/actions/raOptimal/beneficiaries';
import { getRemoveId } from '@om/redux/selectors/raOptimal/beneficiaries';
import { OmApplicationPopupModal, OmIcon } from '@om/component-library-react';
import OmButton from '../../../../components/forms/OmButton';

const RemoveBeneficiaryModal = () => {
    const dispatch = useDispatch();
    const { removeId } = useSelector(
        createStructuredSelector({
            removeId: getRemoveId
        })
    );

    const confirmDelete = () => {
        dispatch(deleteBeneficiary(removeId));
        dispatch(setRemoveId(null));
    };

    return (
        <OmApplicationPopupModal name="removeModal" dismissible>
            <div slot="content">
                <OmIcon image-url="https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/bltc34f1c6e32fb7291/5ec3d2d2a4bead4101d1941f/TrashSVG.svg" />
                <p>Are you sure you want to remove this beneficiary?</p>
            </div>
            <div slot="button">
                <OmButton
                    type="primary"
                    size="small"
                    text="YES, REMOVE"
                    onClick={() => confirmDelete()}
                />
            </div>
        </OmApplicationPopupModal>
    );
};

export default RemoveBeneficiaryModal;
