import React from 'react';

const EffectiveAnnualCost = ({ fund }) => {
    return (
        <div>
            <p>
                The following is an example only, for illustrative purposes. We will send you a full EFFECTIVE ANNUAL COST document
                when we issue you your policy.

            </p>
            <p><strong>Example EAC—Assumes R1000 monthly investment for 20 years into the Old Mutual Balanced Fund</strong></p>
            <p><strong>Effective Annual Cost (EAC) calculation</strong></p>
            <p>
                The Effective Annual Cost (EAC) is a measure which has been introduced to allow you to compare the
                charges you incur and their impact on investment returns when you invest in different financial products.
                It is expressed as an annualised percentage of your investment amount. The EAC is made up of four charges,
                which are added together, as shown in the table below. Some of the charges may vary, depending on your
                investment period. The EAC calculation assumes that an investor terminates his or her investment in the
                financial product at the end of the relevant periods shown in the table.

            </p>

            <table className="eac-table">
                <thead>
                    <tr>
                        <th className="column-1 change-column" rowSpan="2">
                            <p className="medium"><strong> Impact of future charges</strong></p>
                        </th>
                        <th colSpan="4">
                            <p className="medium"><strong>Investment assumed to end after</strong></p>
                        </th>
                    </tr>
                    <tr className="header-row-2">
                        <th className="column-2">
                            <p className="small"><strong>Next 1 Year</strong></p>
                        </th>
                        <th className="column-3">
                            <p className="small"><strong>Next 3 Years</strong></p>
                        </th>
                        <th className="column-4">
                            <p className="small"><strong>Next 5 Years</strong></p>
                        </th>
                        <th className="column-5">
                            <p className="small"><strong>Next 10 Years</strong></p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="column-1">
                            <p className="medium">Investment Management</p>
                        </td>
                        <td className="column-2">
                            <p className="medium">1.3%</p>
                        </td>
                        <td className="column-3">
                            <p className="medium">1.3%</p>
                        </td>
                        <td className="column-4">
                            <p className="medium">1.3%</p>
                        </td>
                        <td className="column-5">
                            <p className="medium">1.3%</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="column-1">
                            <p className="medium">Advice</p>
                        </td>
                        <td className="column-2">
                            <p className="medium">0.0%</p>
                        </td>
                        <td className="column-3">
                            <p className="medium">0.0%</p>
                        </td>
                        <td className="column-4">
                            <p className="medium">0.0%</p>
                        </td>
                        <td className="column-5">
                            <p className="medium">0.0%</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="column-1">
                            <p className="medium">Administration</p>
                        </td>
                        <td className="column-2">
                            <p className="medium">6.8%</p>
                        </td>
                        <td className="column-3">
                            <p className="medium">3.7%</p>
                        </td>
                        <td className="column-4">
                            <p className="medium">3.0%</p>
                        </td>
                        <td className="column-5">
                            <p className="medium">2.4%</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="column-1">
                            <p className="medium">Other</p>
                        </td>
                        <td className="column-2">
                            <p className="medium">0.0%</p>
                        </td>
                        <td className="column-3">
                            <p className="medium">0.0%</p>
                        </td>
                        <td className="column-4">
                            <p className="medium">-1.5%</p>
                        </td>
                        <td className="column-5">
                            <p className="medium">-1.4%</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="column-1">
                            <p className="medium">Effective annual cost taking into account this plan only</p>
                        </td>
                        <td className="column-2 last-row">
                            <p className="medium">8.1%</p>
                        </td>
                        <td className="column-3 last-row">
                            <p className="medium">5.0%</p>
                        </td>
                        <td className="column-4 last-row">
                            <p className="medium">2.9%</p>
                        </td>
                        <td className="column-5 last-row">
                            <p className="medium">2.3%</p>
                        </td>
                    </tr>
                </tbody>
            </table>

            <p>
                For the purposes of calculating EAC, an assumption was made regarding the rate at which your premium will
                increase annually. The rate is currently 4.00%.
            </p>
            <p><strong>The different categories of charges can be explained as follows:</strong></p>
            <p><strong>Investment management charges</strong></p>

            <p>
                This category is a measure of the costs of managing the selected investment fund(s) chosen and is represented
                by the sum of the Total Expense Ratio (TER) and Transaction Costs (TC) applicable to the funds. These costs will
                include the asset management fees, any applicable performance fees and also the costs for trading the underlying
                assets.
            </p>
            <p>
                These charges are deducted from the investment fund(s) by the fund managers of the underlying investment fund(s).
                The percentage charge shown in the table is the average based on your first year's premium allocation across the
                investments funds chosen.
            </p>

            <p><strong>Advice charges</strong></p>
            <p>
                This category includes all charges for adviser remuneration that you pay to your adviser. Because this is an online sale,
                these charges are zero.
            </p>
            <p><strong>Administration charges</strong></p>
            <p>
                This category includes all charges you pay for the administration of the product. Refer to the charges section for full details.
            </p>


            <p><strong>Other charges</strong></p>
            <p>
                This category includes charges that do not fall into the previous three categories.
                For this plan, the following charge(s) is included in this category:
            </p>
            <ol className="alpha-list">
                <li>
                    If you remain committed to your investment goals, you will
                    receive Investment Boosters that are made up of the Optimal
                    Booster and Monthly Booster. For more detail refer to the section
                    on Investment Boosters. This will reduce the total EAC.
                </li>
            </ol>
        </div>
    );
};

export default EffectiveAnnualCost;

