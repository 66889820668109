import { OmApplicationPopupModal, OmIcon } from '@om/component-library-react';
import OmButton from '../../../../components/forms/OmButton';
import React from 'react';

const SuccessfulResponseModal = ({ open, buttonHandler }) => {
    return (
        <OmApplicationPopupModal
            name="successModal"
            dismissible
            open={open}>
            <div slot="content">
                <OmIcon
                    image-url="https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/blt801513b7ef009be6/5f2935c8dfac843bffcdecfa/SUCCESS_CMOS.svg"
                />
                <h5 className="no-bottom-margin">All good to go!</h5>
                <h6 className="no-bottom-margin account-verification-text">
                    We have successfully verified your banking details.
                </h6>
            </div>
            <div slot="button">
                <OmButton
                    type='primary'
                    size='large'
                    text='Okay, thanks'
                    onClick={() => buttonHandler()}
                />
            </div>
        </OmApplicationPopupModal>
    );
};

export default SuccessfulResponseModal;