import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { OmApplicationButton, OmRaBeneficiaries } from '@om/component-library-react';
import MaxAddedModal from './modalsAndDrawers/MaxAddedModal';
import RemoveBeneficiaryModal from './modalsAndDrawers/RemoveBeneficiaryModal';
import AllocationOfBenefitsDrawer from './modalsAndDrawers/AllocationOfBenefitsDrawer';
import BeneficiaryDetailsDrawer from './modalsAndDrawers/BeneficiaryDetailsDrawer';
import BeneficiariesLandingSection from './sections/BeneficiariesLandingSection';
import BeneficiariesDetailsSection from './sections/BeneficiariesDetailsSection';
import BeneficiariesSplitSection from './sections/BeneficiariesSplitSection';

import {
    addNewBeneficiary,
    toggleEditDrawer,
    setAddMoreThanMaxBeneficiariesTracking
} from '@om/redux/actions/raOptimal/beneficiaries';
import {
    getShowBeneficiarySplitScreen,
    getBeneficiaryListLength,
    getAddedMaxBeneficiaries
} from '@om/redux/selectors/raOptimal/beneficiaries';

const BeneficiariesPage = () => {
    const dispatch = useDispatch();
    const {
        showBeneficiarySplitScreen,
        numberOfBeneficiaries,
        addedMaxBeneficiaries
    } = useSelector(
        createStructuredSelector({
            showBeneficiarySplitScreen: getShowBeneficiarySplitScreen,
            numberOfBeneficiaries: getBeneficiaryListLength,
            addedMaxBeneficiaries: getAddedMaxBeneficiaries
        })
    );

    const toggleAddNewBeneficiaryDrawer = () => {
        if (addedMaxBeneficiaries){
            dispatch(setAddMoreThanMaxBeneficiariesTracking());
            return;
        }

        dispatch(addNewBeneficiary());
        dispatch(toggleEditDrawer());
    };

    return (
        <OmRaBeneficiaries>
            <AllocationOfBenefitsDrawer />
            <BeneficiaryDetailsDrawer />
            <RemoveBeneficiaryModal />
            <MaxAddedModal />

            <div>
                { !showBeneficiarySplitScreen ?
                    <div>
                        { numberOfBeneficiaries === 0 ? 
                            <BeneficiariesLandingSection /> :
                            <BeneficiariesDetailsSection />
                        }

                        <OmApplicationButton
                            className="add-beneficiary-button"
                            iconName="add"
                            text="ADD BENEFICIARY"
                            onButtonClicked={() => toggleAddNewBeneficiaryDrawer()}
                            actionType={addedMaxBeneficiaries}
                        />
                    </div> :
                    <BeneficiariesSplitSection />
                }
            </div>



        </OmRaBeneficiaries>
    );
};

export default BeneficiariesPage;
