import React from 'react';
import OmButton from '../forms/OmButton';
import { OmWizardFooter } from '@om/component-library-react';

const ApplicationWizardFooter = ({
    slot,
    stepIndex,
    numberOfSteps,
    showBackButton,
    redirectButton,
    redirectButtonUrl,
    continueButtonText,
    continueDisabled,
    back,
    next
}) => {
    return (
        <OmWizardFooter index={stepIndex} totalCount={numberOfSteps} slot={slot}>
            { showBackButton &&
                <OmButton
                    slot="left"
                    type="text"
                    size="small"
                    text="Previous"
                    disabled="false"
                    onClick={() => back()}
                />
            }

            { !redirectButton ?
                <OmButton
                    slot="right"
                    type="primary"
                    size="small"
                    text={continueButtonText}
                    url="/"
                    disabled={continueDisabled}
                    onClick={() => next()}
                /> :
                <OmButton
                    slot="right"
                    type="primary"
                    size="small"
                    actionType="Url"
                    text={continueButtonText}
                    url={redirectButtonUrl}
                    disabled={continueDisabled}
                />
            }

        </OmWizardFooter>
    );
};

export default ApplicationWizardFooter;
