import React from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getName } from '@om/redux/selectors/raOptimal/captureDetails';
import { getApplicationProposalNumber } from '@om/redux/selectors/raOptimal/review';
import { OmApplicationThankYou, OmApplicationThankYouPage } from '@om/component-library-react'
import ThreeColumnIllustration from '../../../components/ThreeColumnIllustration';

const ThankYouPage = ({ entry }) => {
    const { name, applicationProposalNumber } = useSelector(
        createStructuredSelector({
            name: getName,
            applicationProposalNumber: getApplicationProposalNumber,
        })
    );

    const formattedEntry = () => {
        const thankYouEntry = entry && entry.thank_you[0];
        const closeButton = thankYouEntry && thankYouEntry.close_button_link;
        const successImage = thankYouEntry && thankYouEntry.success_image_link;
        const thankYouCaption = thankYouEntry && thankYouEntry.caption;
        const contactCaption = thankYouEntry && thankYouEntry.contact_caption;
        const button = thankYouEntry && thankYouEntry.button;

        // Three col doesn't come from thank you reference in entry, so aren't referenced the same way
        const threeColLayout = entry && entry.thank_you_page_three_column_illustration[0];

        return {
            thankYouCaption: thankYouCaption,
            contactCaption: contactCaption,
            closeButtonHref: closeButton && closeButton.href,
            successImageHref: successImage && successImage.href,
            button: {
                title: button && button.title,
                href: button && button.href,
            },
            threeColLayout
        }
    };

    return (
        <OmApplicationThankYouPage>
            <OmApplicationThankYou
                slot="thank-you"
                closeButtonLink={formattedEntry().closeButtonHref}
                imageLink={formattedEntry().successImageHref}
                personName={(name && name.value) || ''}
                thankYouCaption={formattedEntry().thankYouCaption}
                referenceNumber={applicationProposalNumber}
                contactCaption={formattedEntry().contactCaption}
                buttonName={formattedEntry().button.title}
                buttonLink={formattedEntry().button.href}
            />

            <ThreeColumnIllustration
                slot="three-col"
                threeColumnIllustrationContent={formattedEntry().threeColLayout}
                buttonType="primary"
            />
        </OmApplicationThankYouPage>
    );
};

export default ThankYouPage;

