import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { navigate } from 'gatsby';

import { OmRaPaymentDetails } from '@om/component-library-react';
import AccountDetailsSection from './sections/AccountDetailsSection';
import LegalDetailsSection from './sections/LegalDetailsSection';
import ErrorResponseModal from './popups/ErrorResponseModal';
import ServerErrorResponseModal from './popups/ServerErrorResponseModal';
import SuccessfulResponseModal from './popups/SuccessfulResponseModal';
import ApplicationModalLoader from '../../../components/applications/ApplicationModalLoader';
import { PRODUCT_PAGE_URL, ACCOUNT_VALIDATION_STATUS } from '@om/redux/actions/raOptimal/types';
import { toggleIncludeAvs, toggleIncludeCdv, setAccountValidationReset, setAccountValidationStatus } from '@om/redux/actions/raOptimal/paymentDetails';
import {
    getAccountValidationStatus,
    getAccountValidationComplete,
    getDisplaySuccessModal,
} from '@om/redux/selectors/raOptimal/paymentDetails';

const PaymentDetailsPage = ({ entry }) => {
    const dispatch = useDispatch();
    const {
        accountValidationStatus,
        accountValidationComplete,
        displaySuccessModal
    } = useSelector(
        createStructuredSelector({
            accountValidationStatus: getAccountValidationStatus,
            accountValidationComplete: getAccountValidationComplete,
            displaySuccessModal: getDisplaySuccessModal
        })
    );

    useEffect(() => {
        if (entry && entry.include_avs) {
            dispatch(toggleIncludeAvs());
        }
        if (entry && entry.include_cdv) {
            dispatch(toggleIncludeCdv());
        }
    }, []);

    return (
        <OmRaPaymentDetails>
            <div>
                <AccountDetailsSection />
                { accountValidationComplete &&
                    <LegalDetailsSection />
                }
            </div>

            <ApplicationModalLoader
                text="Verifying your bank account details"
                verticalAlign="true"
                open={accountValidationStatus === ACCOUNT_VALIDATION_STATUS.PENDING}
            />

            <SuccessfulResponseModal
                open={displaySuccessModal}
                buttonHandler={() => {
                    dispatch(setAccountValidationStatus(ACCOUNT_VALIDATION_STATUS.IDLE));
                }}
            />

            <ErrorResponseModal
                open={accountValidationStatus === ACCOUNT_VALIDATION_STATUS.FAILED}
                buttonHandler={() => {
                    dispatch(setAccountValidationReset());
                }}
            />

            <ServerErrorResponseModal
                open={accountValidationStatus === ACCOUNT_VALIDATION_STATUS.ERROR}
                buttonHandler={() => {
                    dispatch(setAccountValidationReset());
                    navigate(PRODUCT_PAGE_URL);
                }}
            />
        </OmRaPaymentDetails>
    );
};

export default PaymentDetailsPage;
