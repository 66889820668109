import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ApplicationLayout from '../layouts/ApplicationLayout';
import { navigate, graphql } from 'gatsby';
import { Router } from '@reach/router';
import { OmRaApplicationPage } from '@om/component-library-react';

import {
    PAGES,
    PRODUCT_PAGE_URL,
    CVS_URL,
    APP_PAGE_URLS,
    APP_PAGE_ORDER,
    PROGRESS_BAR_STEPS,
    URL_PREFIX,
    APPLICATION_NAME
} from '@om/redux/actions/raOptimal/types';
import ShowBenefitsPage from '../applications/raOptimal/showBenefitsPage/ShowBenefitsPage';
import CaptureDropoffDetailsPage from '../applications/raOptimal/captureDropOffDetailsPage/CaptureDropOffDetailsPage';
import InvestmentSetupPage from '../applications/raOptimal/investmentSetupPage/InvestmentSetupPage';
import PersonalDetailsPage from '../applications/raOptimal/personalDetailsPage/PersonalDetailsPage';
import BeneficiariesPage from '../applications/raOptimal/beneficiariesPage/BeneficiariesPage';
import PaymentDetailsPage from '../applications/raOptimal/paymentDetailsPage/PaymentDetailsPage';
import ReviewPage from '../applications/raOptimal/reviewPage/ReviewPage';
import ThankYouPage from '../applications/raOptimal/thankYouPage/ThankYouPage';
import ApplicationWizardHeader from '../applications/raOptimal/ApplicationWizardHeader';

import ApplicationErrorModal from '../components/applications/ApplicationErrorModal';
import ApplicationModalLoader from '../components/applications/ApplicationModalLoader';
import ApplicationWizardFooter from '../components/applications/ApplicationWizardFooter';

import {
    getDropoffDetailsValid,
    getIncludeDropoffDetails,
} from '@om/redux/selectors/raOptimal/captureDetails';
import {
    setAgeValue,
    setRetireAtValue,
    setMonthlyInvestmentValue,
    setInvestmentSetupFormValues,
    resetInvestmentSetup,
    backInvestmentSetup,
    continueInvestmentSetup,
    invalidContinueInvestmentSetup,
    setIsCvsVerified,
} from '@om/redux/actions/raOptimal/investmentSetup';
import {
    getAge,
    getRetirementAge,
    getMonthlyInvestment,
    getInvestmentSetupFormIsValid,
    getIsCvsVerified,
} from '@om/redux/selectors/raOptimal/investmentSetup';
import {
    invalidContinueCaptureDetails,
    continueCaptureDetails,
    backCaptureDetails,
    resetCaptureDetails,
    toggleIncludeDropoffDetails
} from '@om/redux/actions/raOptimal/captureDetails';
import {
    invalidContinuePersonalDetails,
    continuePersonalDetails,
    backPersonalDetails,
    resetPersonalDetails,
} from '@om/redux/actions/raOptimal/personalDetails';
import {
    backBeneficiariesSplit,
    continueBeneficiariesSplit,
    invalidContinueBeneficiariesSplit,
    resetBeneficiariesSplit
} from '@om/redux/actions/raOptimal/beneficiariesSplit';
import {
    getReviewValid,
    getApplicationSubmissionSuccess,
    getApplicationSubmissionFail,
    getIsWaitingForSubmission
} from '@om/redux/selectors/raOptimal/review';
import { getBeneficiaryListLength, getShowBeneficiarySplitScreen } from '@om/redux/selectors/raOptimal/beneficiaries';
import { backBeneficiaries, continueBeneficiaries, resetBeneficiaries } from '@om/redux/actions/raOptimal/beneficiaries';
import { setPaymentDetailsFormValues } from '@om/redux/actions/raOptimal/paymentDetails';
import {
    sendRaOptimalSubmission,
    setReviewFormValues,
    invalidateReview,
    setWaitingForSubmissionValue,
    resetReview
} from '@om/redux/actions/raOptimal/review';
import {
    continuePaymentDetails,
    backPaymentDetails,
    invalidContinuePaymentDetails,
    resetPaymentDetails
} from '@om/redux/actions/raOptimal/paymentDetails';
import {
    getPaymentDetailsValid,
} from '@om/redux/selectors/raOptimal/paymentDetails';
import { setPersonalDetailFormValues } from '@om/redux/actions/raOptimal/personalDetails';
import { getPersonalDetailsValid } from '@om/redux/selectors/raOptimal/personalDetails';
import { getScrollToId } from '@om/redux/selectors/raOptimal/scrolling';
import { resetScroll } from '@om/redux/actions/raOptimal/scrolling';
import { getFormStatusIsValid } from '@om/redux/selectors/raOptimal/beneficiarySplit';
import { getReferenceData } from '@om/redux/actions/raOptimal/referenceData';
import { navigateToCVSFromParentApp } from '@om/redux/actions/customerVerificationService/routing';
import { PARENT_APPS } from '@om/redux/actions/customerVerificationService/types';
import { getRequestId } from '@om/redux/selectors/raOptimal/sessionCamVariables';
import { useSessionCamVariables } from '../hooks/useSessionCamVariables';
import { setActivePageIndex, resetRouting } from '@om/redux/actions/raOptimal/routing';
import { getActivePageIndex } from '@om/redux/selectors/raOptimal/routing';

const RAApplicationPage = ({ data }) => {
    const {
        title,
        page_background_url,
        mobile_header_texture,
        include_dropoff_page,
        include_cvs,
        four_column_illustration
    } = data.contentstackRetirementAnnuityApplication;

    const dispatch = useDispatch();
    const {
        dropoffDetailsFormStatus,
        personalDetailsValid,
        investmentSetupFormValid,
        paymentDetailsValid,
        reviewValid,
        beneficiarySplitFormStatusValid,

        numberOfBeneficiaries,
        showBeneficiarySplitScreen,

        includeDropoffDetails,

        age,
        retirementAge,
        monthlyInvestment,
        scrollToId,

        applicationSubmissionSuccess,
        applicationSubmissionFail,

        isCvsVerified,
        waitingForSubmission,
        requestId,
        activePageIndex
    } = useSelector(
        createStructuredSelector({
            dropoffDetailsFormStatus: getDropoffDetailsValid,
            personalDetailsValid: getPersonalDetailsValid,
            investmentSetupFormValid: getInvestmentSetupFormIsValid,
            paymentDetailsValid: getPaymentDetailsValid,
            reviewValid: getReviewValid,
            beneficiarySplitFormStatusValid: getFormStatusIsValid,

            numberOfBeneficiaries: getBeneficiaryListLength,
            showBeneficiarySplitScreen: getShowBeneficiarySplitScreen,
            includeDropoffDetails: getIncludeDropoffDetails,

            age: getAge,
            retirementAge: getRetirementAge,
            monthlyInvestment: getMonthlyInvestment,
            scrollToId: getScrollToId,

            applicationSubmissionSuccess: getApplicationSubmissionSuccess,
            applicationSubmissionFail: getApplicationSubmissionFail,

            isCvsVerified: getIsCvsVerified,
            waitingForSubmission: getIsWaitingForSubmission,
            requestId: getRequestId,
            activePageIndex: getActivePageIndex
        })
    );

    const pageIndex = () => { return APP_PAGE_ORDER.indexOf(pageTitle()); };
    const pageTitle = () => { return title.toLowerCase().split(' ').join('');  };
    const progressBarIndex = () => { return Math.max(-1, pageIndex() - 2); };

    useEffect(() => {
        dispatch(getReferenceData());
        if (!include_dropoff_page && pageTitle() === PAGES.SHOW_BENEFITS.KEY) {
            dispatch(toggleIncludeDropoffDetails());
        }

        if (pageIndex() > activePageIndex && pageIndex() > 2) {
            navigateTo(activePageIndex);
        }
    }, []);

    useEffect(() => {
        if (!applicationSubmissionSuccess || !waitingForSubmission)
            return;

        dispatch(setWaitingForSubmissionValue(false));
        const thankYouPageIndex = APP_PAGE_URLS.length - 1;
        navigate(URL_PREFIX + APP_PAGE_URLS[thankYouPageIndex]);
        dispatch(setActivePageIndex(thankYouPageIndex));
    }, [dispatch, waitingForSubmission, applicationSubmissionSuccess]);

    useEffect(() => {
        dispatch(setWaitingForSubmissionValue(false));
    }, [dispatch, applicationSubmissionFail]);

    const isOfficeHours = () => {
        const now = new Date();
        const weekday = now.getDay();
        const hours = now.getHours();
        const [isSunday, isSaturday] = [weekday === 0, weekday === 6]
        const isWeekday = !isSaturday && !isSunday;
        const isWeekdayOfficeHours = hours >= 8 && hours < 18;
        const isSaturdayOfficeHours = hours >= 8 && hours < 13;

        return (isWeekday && isWeekdayOfficeHours) ||
               (isSaturday && isSaturdayOfficeHours)
    };

    const navigateTo = (index) => {
        navigate(URL_PREFIX + APP_PAGE_URLS[index]);
        dispatch(setActivePageIndex(index));
    };

    const routeToNextPage = () => {
        if (pageTitle() === PAGES.CAPTURE_DETAILS.KEY) {
            if (dropoffDetailsFormStatus) {
                navigateTo(pageIndex() + 1);
                dispatch(continueCaptureDetails());
                return;
            }

            dispatch(invalidContinueCaptureDetails());
            return;
        }

        if (pageTitle() === PAGES.INVESTMENT_SETUP.KEY) {
            const currentPageUrl = URL_PREFIX + APP_PAGE_URLS[pageIndex()];
            const nextPageUrl = URL_PREFIX + APP_PAGE_URLS[pageIndex() + 1];

            if (investmentSetupFormValid) {
                if (include_cvs && !isCvsVerified) {
                    navigateToCVSFromParentApp({
                        currentAppUrl: currentPageUrl,
                        parentAppUrl: nextPageUrl,
                        callbackAction: setIsCvsVerified(),
                        callMeBackSource: {
                            redirectUrl: PRODUCT_PAGE_URL,
                            productName: 'MaxInvestments',
                        },
                        parentApp: PARENT_APPS[0]
                    });

                    window.location.href = CVS_URL;
                } else {
                    navigateTo(pageIndex() + 1);
                    dispatch(continueInvestmentSetup());
                }
                return;
            }

            if (!age.value) {
                dispatch(setAgeValue(null));
                return;
            }
            if (!retirementAge.value) {
                dispatch(setRetireAtValue(null));
                return;
            }
            if (!monthlyInvestment.value) {
                dispatch(setMonthlyInvestmentValue(null));
                return;
            }

            dispatch(invalidContinueInvestmentSetup());
            dispatch(setInvestmentSetupFormValues());
            return;
        }

        if (pageTitle() === PAGES.PERSONAL_DETAILS.KEY) {
            if (personalDetailsValid) {
                navigateTo(pageIndex() + 1);
                dispatch(continuePersonalDetails());
                return;
            }

            dispatch(invalidContinuePersonalDetails());
            dispatch(setPersonalDetailFormValues());
        }

        if (pageTitle() === PAGES.BENEFICIARIES.KEY) {
            if (showBeneficiarySplitScreen) {
                if (beneficiarySplitFormStatusValid) {
                    navigateTo(pageIndex() + 1);
                    dispatch(continueBeneficiariesSplit());
                    return;
                }

                dispatch(invalidContinueBeneficiariesSplit());
                return;
            }

            if (numberOfBeneficiaries < 2) {
                navigateTo(pageIndex() + 1);
            }

            // continue to next screen -- split screen or payment details
            dispatch(continueBeneficiaries());
        }

        if (pageTitle() === PAGES.PAYMENT_DETAILS.KEY) {
            if (paymentDetailsValid) {
                navigateTo(pageIndex() + 1);
                dispatch(continuePaymentDetails());
                return;
            }

            dispatch(invalidContinuePaymentDetails());
            dispatch(setPaymentDetailsFormValues());
        }

        if (pageTitle() === PAGES.CONFIRMATION.KEY) {
            if (reviewValid) {
                dispatch(sendRaOptimalSubmission());
                dispatch(setWaitingForSubmissionValue(true));
                return;
            }

            dispatch(invalidateReview());
            dispatch(setReviewFormValues());
        }
    };

    const routeToPreviousPage = () => {
        if (pageTitle() === PAGES.CAPTURE_DETAILS.KEY)
            dispatch(backCaptureDetails());

        if (pageTitle() === PAGES.INVESTMENT_SETUP.KEY)
            dispatch(backInvestmentSetup());

        if (pageTitle() === PAGES.PERSONAL_DETAILS.KEY)
            dispatch(backPersonalDetails());

        if (pageTitle() === PAGES.BENEFICIARIES.KEY) {
            if (showBeneficiarySplitScreen) {
                dispatch(backBeneficiariesSplit());
                return;
            }
            dispatch(backBeneficiaries());
        }

        if (pageTitle() === PAGES.PAYMENT_DETAILS.KEY) {
            dispatch(backPaymentDetails());
        }

        if (!includeDropoffDetails && pageTitle() === PAGES.INVESTMENT_SETUP.KEY) {
            navigate(URL_PREFIX + APP_PAGE_URLS[pageIndex() - 2]);
            return;
        }

        navigate(URL_PREFIX + APP_PAGE_URLS[pageIndex() - 1]);
    };

    const closeButtonClick = () => {
        navigate('/');
        dispatch(resetCaptureDetails());
        dispatch(resetPersonalDetails());
        dispatch(resetInvestmentSetup());
        dispatch(resetBeneficiaries());
        dispatch(resetBeneficiariesSplit());
        dispatch(resetPaymentDetails());
        dispatch(resetReview());
        dispatch(resetScroll());
        dispatch(resetRouting());
    };

    useSessionCamVariables(requestId);

    return (
        <ApplicationLayout has-background={!!page_background_url}>
            <OmRaApplicationPage
                scrollToId={scrollToId}
                background={page_background_url ? page_background_url.url : ''}
                onScrollEnd={() => dispatch(resetScroll())}
            >
                <div slot="header">
                    <ApplicationWizardHeader
                        contactNumber="0860 665 463"
                        headerTextureUrl={mobile_header_texture ? mobile_header_texture.url : ''}
                        title="Optimal Retirement Annuity Application"
                        includeRaLiveChat
                        isOfficeHours={isOfficeHours()}
                        progressBarIndex={progressBarIndex()}
                        displayProgressBar={pageTitle() !== PAGES.THANK_YOU.KEY}
                        applicationName={APPLICATION_NAME}
                        progressBarSteps={PROGRESS_BAR_STEPS}
                        applicationSteps={PROGRESS_BAR_STEPS}
                        hideDesktop={progressBarIndex() < 0}
                        hideMobile={progressBarIndex() < 0}
                        closeButtonhandler={() => closeButtonClick()}
                    />
                </div>

                <div slot="content-no-sidebar">
                    <Router>
                        <ShowBenefitsPage
                            path={PAGES.SHOW_BENEFITS.FULL_URL}
                            entry={four_column_illustration}
                        />
                        <CaptureDropoffDetailsPage path={PAGES.CAPTURE_DETAILS.FULL_URL}/>
                        <InvestmentSetupPage path={PAGES.INVESTMENT_SETUP.FULL_URL}/>
                        <PersonalDetailsPage path={PAGES.PERSONAL_DETAILS.FULL_URL}/>
                        <BeneficiariesPage path={PAGES.BENEFICIARIES.FULL_URL}/>
                        <PaymentDetailsPage
                            path={PAGES.PAYMENT_DETAILS.FULL_URL}
                            entry={data.contentstackRetirementAnnuityApplication}
                        />
                        <ReviewPage path={PAGES.CONFIRMATION.FULL_URL}/>
                        <ThankYouPage
                            path={PAGES.THANK_YOU.FULL_URL}
                            entry={data.contentstackRetirementAnnuityApplication} />
                    </Router>
                </div>

                {
                    (pageTitle() !== PAGES.SHOW_BENEFITS.KEY && pageTitle() !== PAGES.THANK_YOU.KEY) &&
                        <ApplicationWizardFooter
                            slot="footer"
                            continueButtonText={pageTitle() === PAGES.CONFIRMATION.KEY ? 'SUBMIT' : 'CONTINUE'}
                            stepIndex={progressBarIndex()}
                            numberOfSteps="6"
                            backDisabled="false"
                            continueDisabled={waitingForSubmission}
                            showBackButton
                            back={() => routeToPreviousPage()}
                            next={() => routeToNextPage()}
                            redirectButtonUrl="/"
                        />
                }

            </OmRaApplicationPage>

            <ApplicationErrorModal
                heading="Oops! Something went wrong."
                text="It's not you. It's us. Don't worry - we have your details and will be in touch shortly to help you complete your application. We apologise for the inconvenience."
                open={applicationSubmissionFail}
                onClose={() => navigate('/')}
                buttonText="GO TO HOME"
            />

            <ApplicationModalLoader
                text="Submitting your application"
                verticalAlign="true"
                open={waitingForSubmission}
            />


        </ApplicationLayout>
    );
};

export const pageQuery = graphql`
    query RAApplicationPageQuery($id: String!) {
        contentstackRetirementAnnuityApplication(id: { eq: $id }) {
            uid
            title
            page_background_url {
                ...File
            }
            four_column_illustration {
                ...FourColumnIllustration
            }
            mobile_header_texture {
                ...File
            }

            thank_you_close_button_link {
                ...Link
            }
            thank_you_success_image_link {
                ...Link
            }
            thank_you_caption
            thank_you_contact_caption
            thank_you_button {
                title
                ...Link
            }
            include_dropoff_page
            include_cvs
            include_avs
            include_cdv
            thank_you {
                ...ThankYou
            }
            thank_you_page_three_column_illustration {
                ...ThreeColumnIllustration
            }
        }
    }
`;

export default RAApplicationPage;
