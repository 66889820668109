import React from 'react';

const FeesChargesBoosters = ({ fund }) => {
    return (
        <div>
            <p><strong>Asset Charges: </strong></p>
            <p>
                Initial Asset Plan Charge: 1.825% per year (nominal) as a % of each Investment Fund <br/><br/>
                Administration Charge: R26.00 per month
            </p>
            <ul>
                <li>There are no Premium Plan Charges. Should you wish to invest in certain external Unit Trust Funds in future,
                    then a Premium Plan Charge may apply.</li><br/>
                <li>The Asset Plan Charges are reflected in the table below:</li>
            </ul>

            <table className="asset-plan-table">
                <thead>
                    <tr>
                        <th className="column-1">
                            <p className="medium"><strong>Fund Value Band</strong></p>
                        </th>
                        <th>
                            <p className="medium"><strong>Asset Plan Charge per year (nominal)</strong></p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="column-1">
                            <p className="medium">First R62,400</p>
                        </td>
                        <td className="column-2">
                            <p className="medium">1.825%</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="column-1">
                            <p className="medium">Next R37,600</p>
                        </td>
                        <td className="column-2">
                            <p className="medium">2.325%</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="column-1">
                            <p className="medium">Above R100,000</p>
                        </td>
                        <td className="column-2">
                            <p className="medium">2.025%</p>
                        </td>
                    </tr>
                </tbody>
            </table>

            <ul>
                <li>The Asset Plan Charge is paid to Old Mutual and is deducted monthly in arrears from each investment fund.</li><br/>
                <li>The Asset Plan charge is lower when Investment Funds managed by the Old Mutual Investment Group (SA)
                    are selected. The charge can be reduced by as much as 0.350% per year (nominal) for the proportion invested in these funds.</li><br/>
                <li>The initial Asset Plan Charges are based on the initial net amount invested and the initial Investment Fund allocation.</li><br/>
                <li>Subsequent Asset Plan Charges are based on the total fund value at the time one charge is deducted.</li><br/>
                <li>Old Mutual determines the Asset Plan charges with reference to Fund Value bands. The rand value of the fund value bands
                    will increase each year with inflation as determined by Old Mutual Life Assurance Company (South Africa) (OMLAC (SA)).</li><br/>
                <li>The monthly Administration Charge of R26.00 will be deducted proportionally from the funds in your plan. This charge
                    will be increased annually with inflation, at the discretion of Old Mutual.</li><br/>
                <li>The above Asset Plan Charge currently does not include an adviser remuneration component. However, should adviser
                    remuneration be paid in future, then a charge of 1.23% will be added. When adviser remuneration expenses are fully recovered, this charge will stop.</li><br/>
            </ul>

            <p><strong>Detail of fund fees and charges</strong></p>

            <table className="fees-table">
                <thead>
                    <tr>
                        <th className="column-1 fees-column">
                            <p className="medium"><strong>Fund Name</strong></p>
                        </th>
                        <th>
                            <p className="medium"><strong>Asset Management Fee % (of fund value)</strong></p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="column-1">
                            <p className="medium">
                                {fund === 1
                                    ? <><strong>Comfortable</strong> - <br />Old Mutual Balanced Fund</>
                                    : <><strong>Adventurous</strong> - <br />Old Mutual Edge 28 Life Fund</>
                                }
                            </p>
                        </td>
                        <td className="column-2">
                            <p className="medium">{fund === 1 ? `1.08%` : `1.30%`}</p>
                        </td>
                    </tr>
                </tbody>
            </table>

            <p>Notes to your Investment Funds </p>
            <p><strong>Asset Management Fees</strong></p>
            <p>

                The total asset management fee charged may vary depending on the performance of each fund relative to its objectives.
                Unless otherwise stated the asset management fee quoted is the fee applicable at the fund's benchmark. Actual fees
                payable could be less or more depending on the fund's performance.<br/><br/>
                The basis on which performance fees are calculated varies from fund to fund and may change from time to time. Detail
                on the calculation of the performance fees are disclosed on the fund fact sheets which can be downloaded.
            </p>
            <p><strong>MORE ABOUT YOUR OPTIMAL INVESTMENT PLAN 1</strong></p>
            <p><strong>Investment Boosters</strong></p>
            <ol className="boldlist">
                <li>Optimal Booster<br/><span className="nonbold">
                    By staying committed to your investment goals in the first five
                    years of your plan, you will then qualify for an Optimal Booster.
                    This booster is calculated at 2.5% of your fund value and is
                    payable on the day before the 5th anniversary of your plan.</span></li><br/>

                <li>Monthly Boosters<br/><span className="nonbold">
                    You will also start receiving Monthly Boosters as per the table
                    below:</span></li>
            </ol>


            <table className="monthly-booster-table">
                <thead>
                    <tr>
                        <th className="column-1 plan-year-column">
                            <p className="medium"><strong>Plan Year</strong></p>
                        </th>
                        <th>
                            <p className="medium"><strong>Monthly Booster %</strong></p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="column-1">
                            <p className="medium">6-10</p>
                        </td>
                        <td className="column-2">
                            <p className="medium">1.00%</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="column-1">
                            <p className="medium">11-15</p>
                        </td>
                        <td className="column-2">
                            <p className="medium">1.25%</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="column-1">
                            <p className="medium">16-20</p>
                        </td>
                        <td className="column-2">
                            <p className="medium">1.50%</p>
                        </td>
                    </tr>
                    <tr>
                        <td className="column-1">
                            <p className="medium">21-25</p>
                        </td>
                        <td className="column-2">
                            <p className="medium">1.75%</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default FeesChargesBoosters;

