import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ApplicationBeneficiaryDetailsList from '../../../../components/applications/ApplicationBeneficiaryDetailsList';

import {
    editBeneficiary,
    toggleEditDrawer,
    setDeleteBenificiaryPopupTracking,
    setRemoveId
} from '@om/redux/actions/raOptimal/beneficiaries';
import { getBeneficiaryList } from '@om/redux/selectors/raOptimal/beneficiaries';

const BeneficiariesDetailsSection = () => {
    const dispatch = useDispatch();
    const { beneficiaryList } = useSelector(
        createStructuredSelector({
            beneficiaryList: getBeneficiaryList
        })
    );

    const toggleEditBeneficiaryDrawer = (id) => {
        dispatch(editBeneficiary(id));
        dispatch(toggleEditDrawer());
    };
    const toggleRemoveModal = (id) => {
        dispatch(setDeleteBenificiaryPopupTracking());
        dispatch(setRemoveId(id))
    };

    return (
        <div>
            <h5 className="no-top-margin">
                Your <span className="medium"> beneficiary list </span>
            </h5>
            <ApplicationBeneficiaryDetailsList
                beneficiaryList={beneficiaryList}
                editHandler={(id) => toggleEditBeneficiaryDrawer(id)}
                removeHandler={(id) => toggleRemoveModal(id)}
            />
        </div>
    );
};

export default BeneficiariesDetailsSection;

