import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DEFAULT_VALIDATION_DELAY } from '../../config';
import {
    OmFormInputFieldWrapper,
    OmRaCaptureDetails,
    OmMobileInputFieldWrapper,
} from '@om/component-library-react';

import {
    setNameValue,
    setSurnameValue,
    setMobileValue,
} from '@om/redux/actions/raOptimal/captureDetails';
import { getName, getSurname, getMobile } from '@om/redux/selectors/raOptimal/captureDetails';
import { FORM_STATUS } from '@om/redux/reducers/status';

const CaptureDropOffDetailsPage = () => {
    const dispatch = useDispatch();
    const { name, surname, mobile } = useSelector(
        createStructuredSelector({
            name: getName,
            surname: getSurname,
            mobile: getMobile,
        })
    );

    return (
        <OmRaCaptureDetails>
            <h4 slot="desktop-heading">
                Before we start this five-step process, <br />
                <strong>tell us about yourself</strong>
            </h4>
            <h4 slot="mobile-heading">
                Before we start this five-step process, <strong>tell us about yourself</strong>
            </h4>
            <div slot="form">
                <section className="form-section">
                    <OmFormInputFieldWrapper
                        isSensitive
                        placeholder="First name"
                        required="true"
                        size="medium"
                        formId="captureDetailsForm"
                        name="name"
                        align="left"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={name.value}
                        state={name.status}
                        errorMessage={name.error}
                        onPersist={(event) => dispatch(setNameValue(event.detail))}
                    />
                    <OmFormInputFieldWrapper
                        isSensitive
                        placeholder="Surname"
                        required="true"
                        size="medium"
                        formId="captureDetailsForm"
                        name="surname"
                        align="left"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={surname.value}
                        state={surname.status}
                        errorMessage={surname.error}
                        onPersist={(event) => dispatch(setSurnameValue(event.detail))}
                    />
                    <OmMobileInputFieldWrapper
                        form-id="captureDetailsForm"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={mobile.value}
                        state={mobile.status}
                        errorMessage={mobile.error}
                        onPersist={(event) => dispatch(setMobileValue(event.detail))}
                        hintText={
                            mobile.status === FORM_STATUS.VALID
                                ? ''
                                : 'Remember to add “0” in front of your mobile number. E.g. 082 123 4567'
                        }
                    />
                </section>
            </div>
            <p slot="assist-text">
                If you've had any trouble completing this application, Old Mutual would like to
                offer some help by contacting you.
            </p>
        </OmRaCaptureDetails>
    );
};

export default CaptureDropOffDetailsPage;
