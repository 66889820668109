import React from 'react';
import {
    OmApplicationBeneficiaryList,
    OmApplicationBeneficiarySplitCard,
    OmFormInputFieldWrapper
} from '@om/component-library-react';

const ApplicationBeneficiarySplitList = ({
    beneficiaryList,
    beneficiaryListStatus,
    totalPercentage,
    splitError,
    isEvenSplit,
    splitFields,
    checkBoxChangedHandler,
    inputPersistHandler
}) => {
    return (
        <OmApplicationBeneficiaryList
            evenSplit={isEvenSplit}
            totalPercentage={totalPercentage}
            errorMessage={splitError}
            state={beneficiaryListStatus}
            onCheckBoxChanged={() => checkBoxChangedHandler()}
        >
            <div slot="beneficiary-cards">
                { beneficiaryList && beneficiaryList.map((beneficiary, index) => {
                    return (
                        <OmApplicationBeneficiarySplitCard
                            id={index}
                            key={index}
                            number={index + 1}
                            name={beneficiary.value.firstName.value}
                            surname={beneficiary.value.surname.value}
                            errorMessage={splitFields[index].error}
                        >
                            <OmFormInputFieldWrapper
                                maxLength="2"
                                slot="percentage-input"
                                align="center"
                                name="percentage"
                                keyboardMode="numeric"
                                value={splitFields[index].value}
                                state={splitFields[index].status}
                                onPersist={(event) => inputPersistHandler(event.detail, beneficiary.id)}
                            />
                        </OmApplicationBeneficiarySplitCard>
                    )})
                }
            </div>
        </OmApplicationBeneficiaryList>
    );
};

export default ApplicationBeneficiarySplitList;

