import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { toggleReadMoreDrawer } from '@om/redux/actions/raOptimal/beneficiaries';
import { getReadMoreDrawerIsOpen } from '@om/redux/selectors/raOptimal/beneficiaries';
import { OmSideDrawer } from '@om/component-library-react';

const AllocationOfBenefitsDrawer = () => {
    const dispatch = useDispatch();
    const { open } = useSelector(
        createStructuredSelector({
            open: getReadMoreDrawerIsOpen
        })
    );

    return (
        <OmSideDrawer
            onOnSideDrawerClosed={() => dispatch(toggleReadMoreDrawer())}
            open={open}>
            <div slot="side-drawer-content">
                <div>
                    <h5 className="no-top-margin"><strong> Allocation of benefits </strong></h5>
                    <p>
                        In terms of legislation, the trustees of the Retirement Fund i.e. South African Retirement Annuity
                        Fund (SARAF), will decide how the benefits of the policy will be allocated between your dependants
                        and/or your nominated beneficiaries. This means that the trustees may not necessarily pay out the
                        benefits according to your elected beneficiary nomination. Your nomination will, however, be
                        considered.
                    </p>
                    <p>
                        Benefits will be paid to your nominated beneficiaries provided that your estate is solvent and your
                        dependants and any estate debt have been taken care of first. Where no legal dependents exist,
                        benefits shall be allocated to the nominated beneficiaries.
                    </p>
                </div>
            </div>
        </OmSideDrawer>
    );
};

export default AllocationOfBenefitsDrawer;

