import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import { DEFAULT_VALIDATION_DELAY } from '../../../config';
import { OmFormDropdownFieldWrapper, OmFormInputFieldWrapper } from '@om/component-library-react';

import { getName } from '@om/redux/selectors/raOptimal/captureDetails';
import {
    setSourceOfIncomeValue,
    setSourceOfIncomeOtherValue,
    setSourceOfFundsValue,
    setEmploymentPositionValue,
    setEmploymentIndustryValue,
    setEmploymentIndustryOtherValue
} from '@om/redux/actions/raOptimal/paymentDetails';
import {
    getSourceOfIncomeOtherRequired,
    getEmploymentIndustryRequired,
    getEmploymentIndustryOtherRequired,
    getSourceOfIncome,
    getSourceOfIncomeOther,
    getSourceOfFunds,
    getEmploymentPosition,
    getEmploymentIndustry,
    getEmploymentIndustryOther
} from '@om/redux/selectors/raOptimal/paymentDetails';
import {
    getReferenceSourceOfIncomes,
    getReferenceEmploymentPositions,
    getReferenceEmploymentIndustries,
    getReferenceSourceOfFunds
} from '@om/redux/selectors/raOptimal/referenceData';

const LegalDetailsSection = () => {
    const dispatch = useDispatch();
    const {
        sourceOfIncomeOtherRequired,
        employmentIndustryRequired,
        employmentIndustryOtherRequired,
        name,
        sourceOfIncome,
        sourceOfIncomeOther,
        sourceOfFunds,
        employmentPosition,
        employmentIndustry,
        employmentIndustryOther,
        referenceSourceOfIncomes,
        referenceEmploymentPositions,
        referenceEmploymentIndustries,
        referenceSourceOfFunds
    } = useSelector(
        createStructuredSelector({
            sourceOfIncomeOtherRequired: getSourceOfIncomeOtherRequired,
            employmentIndustryRequired: getEmploymentIndustryRequired,
            employmentIndustryOtherRequired: getEmploymentIndustryOtherRequired,
            name: getName,
            sourceOfIncome: getSourceOfIncome,
            sourceOfIncomeOther: getSourceOfIncomeOther,
            sourceOfFunds: getSourceOfFunds,
            employmentPosition: getEmploymentPosition,
            employmentIndustry: getEmploymentIndustry,
            employmentIndustryOther: getEmploymentIndustryOther,
            referenceSourceOfIncomes: getReferenceSourceOfIncomes,
            referenceEmploymentPositions: getReferenceEmploymentPositions,
            referenceEmploymentIndustries: getReferenceEmploymentIndustries,
            referenceSourceOfFunds: getReferenceSourceOfFunds
        })
    );

    return (
        <div className="legal-section" id="legal-anchor">
            <h4 className="heading">
                Your source of <strong>income</strong>
            </h4>
            <p className="subheading">
                {name.value}, to protect you and fellow South Africans from fraud and money laundering, we need to know about your earnings.
            </p>
            <section className="form">
                <OmFormDropdownFieldWrapper
                    useNativeMobileDropdown
                    id="sourceOfIncome"
                    required
                    form-id="paymentDetailsForm"
                    placeholder="Source of income"
                    name="sourceOfIncome"
                    align="left"
                    value={sourceOfIncome.value}
                    state={sourceOfIncome.status}
                    errorMessage={sourceOfIncome.error}
                    options={referenceSourceOfIncomes &&
                        JSON.stringify(referenceSourceOfIncomes.map(option => ({ value: option.code, label: option.description })))
                    }
                    onOnSelect={event => dispatch(setSourceOfIncomeValue(event.detail.id)) }
                />
                { sourceOfIncomeOtherRequired &&
                    <OmFormInputFieldWrapper
                        id="sourceOfIncomeOther"
                        placeholder="Please specify"
                        required
                        size="full"
                        form-id="paymentDetailsForm"
                        name="sourceOfIncomeOther"
                        align="left"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={sourceOfIncomeOther.value}
                        state={sourceOfIncomeOther.status}
                        errorMessage={sourceOfIncomeOther.error}
                        onPersist={(event) => dispatch(setSourceOfIncomeOtherValue(event.detail))}
                    />
                }
                <OmFormDropdownFieldWrapper
                    useNativeMobileDropdown
                    id="sourceOfFunds"
                    required
                    form-id="paymentDetailsForm"
                    placeholder="Source of funds"
                    name="sourceOfFunds"
                    align="left"
                    value={sourceOfFunds.value}
                    state={sourceOfFunds.status}
                    errorMessage={sourceOfFunds.error}
                    options={referenceSourceOfFunds &&
                        JSON.stringify(referenceSourceOfFunds.map(option => ({ value: option.code, label: option.description })))
                    }
                    onOnSelect={event => dispatch(setSourceOfFundsValue(event.detail.id)) }
                />
                <OmFormDropdownFieldWrapper
                    useNativeMobileDropdown
                    id="employmentPosition"
                    required
                    form-id="paymentDetailsForm"
                    placeholder="What do you do for a living?"
                    name="employmentPosition"
                    align="left"
                    value={employmentPosition.value}
                    state={employmentPosition.status}
                    errorMessage={employmentPosition.error}
                    options={referenceEmploymentPositions &&
                        JSON.stringify(referenceEmploymentPositions.map(option => ({ value: option.code, label: option.description })))
                    }
                    onOnSelect={event => dispatch(setEmploymentPositionValue(event.detail.id)) }
                />
                { employmentIndustryRequired && 
                    <OmFormDropdownFieldWrapper
                        useNativeMobileDropdown
                        v-show="employmentIndustryRequired"
                        id="employmentIndustry"
                        required="true"
                        form-id="paymentDetailsForm"
                        placeholder="What industry do you work in?"
                        name="employmentIndustry"
                        align="left"
                        value={employmentIndustry.value}
                        state={employmentIndustry.status}
                        errorMessage={employmentIndustry.error}
                        options={referenceEmploymentIndustries &&
                            JSON.stringify(referenceEmploymentIndustries.map(option => ({ value: option.code, label: option.description })))
                        }
                        onOnSelect={event => dispatch(setEmploymentIndustryValue(event.detail.id)) }
                    />
                }
                { employmentIndustryOtherRequired &&   
                    <OmFormInputFieldWrapper
                        id="employmentIndustryOther"
                        placeholder="Please specify"
                        required
                        size="full"
                        form-id="paymentDetailsForm"
                        name="employmentIndustryOther"
                        align="left"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        value={employmentIndustryOther.value}
                        state={employmentIndustryOther.status}
                        errorMessage={employmentIndustryOther.error}
                        onPersist={(event) => dispatch(setEmploymentIndustryOtherValue(event.detail))}
                    />
                } 
            </section>
        </div>
    );
};

export default LegalDetailsSection;

