import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {
    OmFormInputFieldWrapper,
    OmFormDropdownFieldWrapper,
    OmApplicationRadioButtonAccordionGroup,
    OmApplicationRadioButtonAccordionItem,
    OmApplicationRadioButtonAccordionItemBold,
    OmFormError,
    OmIcon
} from '@om/component-library-react';
import OmButton from '../../../components/forms/OmButton';
import { DEFAULT_VALIDATION_DELAY } from '../../config';
import {
    getComfortableInvestorFactSheetUrl,
    getAdventurousInvestorFactSheetUrl
} from '@om/redux/selectors/raOptimal/referenceData';
import {
    getAge,
    getRetirementAge,
    getRetirementAgeOptions,
    getMonthlyInvestment,
    getMonthlyInvestmentDerivedTerm,

    getChooseOwnPercentageIsSelected,
    getPremiumEscalationError,
    getSelectedIncreaseRadioId,
    getSelectedIncreaseComplete,
    getInvestorTypeError,
    getInvestmentFund,
    getPremiumEscalationText,
    getLinkWithInflationIsSelected,
    getNoIncreaseIsSelected,
    getInvestorType,
    getOwnPercentage,
} from '@om/redux/selectors/raOptimal/investmentSetup';
import {
    setAgeValue,
    setRetireAtValue,
    setMonthlyInvestmentValue,
    setOwnPercentageValue,
    selectLinkWithInflation,
    selectChooseOwnPercentage,
    selectNoIncrease,
    setFactSheetClicked,
    selectComfortableInvestor,
    selectAdventurousInvestor,
    resetOwnPercentageValue,
} from '@om/redux/actions/raOptimal/investmentSetup';
import { transitionScrolling } from '@om/redux/actions/raOptimal/scrolling';
import { getReferenceOwnPercentages } from '@om/redux/selectors/raOptimal/referenceData';
import { FIELD_STATUS } from '@om/redux/reducers/status';
import './InvestmentSetupPage.scss';

const InvestmentSetupPage = () => {
    const dispatch = useDispatch();
    const {
        age,
        retirementAge,
        retirementAgeOptions,
        derivedInvestmentTerm,
        monthlyInvestment,

        increaseOwnAmount,
        isIncreaseOwnAmountSelected,
        linkWithInflation,
        noIncrease,
        escalationText,
        escalationError,
        yearlyIncrease,
        yearlyIncreaseSectionCompleted,

        investorTypeError,
        comfortableInvestorFactSheet,
        adventurousInvestorFactSheet,
        investorType,
        referencePercentages
    } = useSelector(
        createStructuredSelector({
            age: getAge,
            retirementAge: getRetirementAge,
            retirementAgeOptions: getRetirementAgeOptions,
            derivedInvestmentTerm: getMonthlyInvestmentDerivedTerm,
            monthlyInvestment: getMonthlyInvestment,

            linkWithInflation: getLinkWithInflationIsSelected,
            noIncrease: getNoIncreaseIsSelected,
            increaseOwnAmount: getOwnPercentage,
            isIncreaseOwnAmountSelected: getChooseOwnPercentageIsSelected,
            escalationText: getPremiumEscalationText,
            escalationError: getPremiumEscalationError,
            yearlyIncrease: getSelectedIncreaseRadioId,
            yearlyIncreaseSectionCompleted: getSelectedIncreaseComplete,
            fundSelection: getInvestmentFund,

            investorTypeError: getInvestorTypeError,
            comfortableInvestorFactSheet: getComfortableInvestorFactSheetUrl,
            adventurousInvestorFactSheet: getAdventurousInvestorFactSheetUrl,
            investorType: getInvestorType,
            referencePercentages: getReferenceOwnPercentages
        })
    );

    const handleInvestorTypeSelection = (key) => {
        if (key === '1') dispatch(selectComfortableInvestor());
        else if (key === '2') dispatch(selectAdventurousInvestor());
    };

    const handlePremiumEscalationSelection = (key) => {
        if (key === '1' && !linkWithInflation) dispatch(selectLinkWithInflation());
        else if (key === '2' && !isIncreaseOwnAmountSelected) dispatch(selectChooseOwnPercentage());
        else if (key === '3' && !noIncrease) dispatch(selectNoIncrease());
        if(key) dispatch(transitionScrolling.scrollToId('typeOfInvestor'));
    };

    const updateAgePersist = (event) => {
        if (age.value !== event) {
            dispatch(setAgeValue(event));
        }
    };

    const updateMonthlyInvestmentPersist = (event) => {
        if (monthlyInvestment.value !== event) {
            dispatch(setMonthlyInvestmentValue(event));
        }
    };

    const monthlyInvestmentShowsValid = (showsValid) => {
        if (showsValid && monthlyInvestment.status) {
            dispatch(transitionScrolling.scrollToId('yearlyIncrease'));
        }
    };

    return (
        <div className="ra-investment-setup-page">
            <h4 className="title no-top-margin">Let’s set up <span className="medium"> your investment </span> </h4>
            <section className="section-1">
                <span className="inline-wrapper" >
                    I am &nbsp;
                    <OmFormInputFieldWrapper
                        className="inline-field"
                        id="age"
                        required="true"
                        size="small"
                        name="age"
                        align="left"
                        mask="thousands-spaced"
                        validationDelay={DEFAULT_VALIDATION_DELAY}
                        errorMessageDisplayType="pop-up"
                        errorMessagePopupAlignment="center"
                        errorMessagePopupAlignmentMobile="left"
                        value={age.value}
                        state={age.status}
                        errorMessage={age.error}
                        onPersist={(event) => updateAgePersist(event.detail)}
                    />
                    &nbsp;
                    years old. &nbsp;
                </span>
                { age.status === FIELD_STATUS.VALID &&
                    <section className="retire-at-input inline-wrapper">
                        I would like to retire at &nbsp;
                        <OmFormDropdownFieldWrapper
                            useNativeMobileDropdown
                            required
                            className="inline-field"
                            id="retirementAge"
                            size='small'
                            placeholder=""
                            validationDelay={DEFAULT_VALIDATION_DELAY}
                            name="retirementAge"
                            align="left"
                            errorMessageDisplayType="pop-up"
                            errorMessagePopupAlignment="center"
                            errorMessagePopupAlignmentMobile="center"
                            onOnSelect={(event) => dispatch(setRetireAtValue(event.detail.value))}
                            value={retirementAge.value}
                            state={retirementAge.status}
                            errorMessage={retirementAge.error}
                            options={
                                JSON.stringify(retirementAgeOptions.map(option => ({ value: option, label: option })))
                            }
                        />
                    </section>
                }
                { age.status === FIELD_STATUS.VALID && retirementAge.status === FIELD_STATUS.VALID &&
                    <section
                        className="monthly-investment-input inline-wrapper"
                    >
                        <span className="wrap-line">
                            Over the next <strong> &nbsp; {derivedInvestmentTerm} years, &nbsp; </strong> I would like to &nbsp;
                        </span>
                        invest
                        <div className="rand-spacer"></div>
                        <span className="rand-input-wrapper">
                            <span className="rand"> R </span>
                            <OmFormInputFieldWrapper
                                className="inline-field"
                                id="monthlyInvestment"
                                required="true"
                                size="small"
                                name="monthlyInvestment"
                                maxlength="6"
                                align="left"
                                mask="thousands-spaced"
                                validationDelay={DEFAULT_VALIDATION_DELAY}
                                errorMessageDisplayType="pop-up"
                                errorMessagePopupAlignment="center"
                                errorMessagePopupAlignmentMobile="center"
                                value={monthlyInvestment.value}
                                state={monthlyInvestment.status}
                                error-message={monthlyInvestment.error}
                                onShowingValidation={(event) => monthlyInvestmentShowsValid(event.detail)}
                                onPersist={(event) => updateMonthlyInvestmentPersist(event.detail)}
                            />
                        </span>
                        monthly. &nbsp;
                    </section>
                }
            </section>
            { monthlyInvestment.status === FIELD_STATUS.VALID &&
                <section className="premium-escalation" id="yearlyIncrease">
                    <p className="sessioncamhidetext text-wrap">{escalationText}</p>
                    <h5>What would <strong>you prefer?</strong></h5>
                    <OmApplicationRadioButtonAccordionGroup
                        selectedValue={yearlyIncrease}
                        onOnSelection={(event) => handlePremiumEscalationSelection(event.detail)}
                    >
                        <div slot="radio-accordion-items">
                            <OmApplicationRadioButtonAccordionItem
                                onOnChange={() => dispatch(selectLinkWithInflation(), resetOwnPercentageValue())}
                                buttonGroupName="premiumEscalation"
                                radioButtonId="1"
                                radioButtonTitle="Link it with inflation"
                                description="Your contribution amount will increase by 5%.">
                            </OmApplicationRadioButtonAccordionItem>
                            <OmApplicationRadioButtonAccordionItem
                                onOnChange={() => dispatch(selectChooseOwnPercentage())}
                                buttonGroupName="premiumEscalation"
                                radioButtonId="2"
                                radioButtonTitle="Choose my own percentage"
                                description="Choose your own fixed yearly percentage.">
                                <div className="percentage-panel-content" slot="panel-content">
                                    <OmFormDropdownFieldWrapper
                                        useNativeMobileDropdown
                                        required
                                        id="ownPercentage"
                                        size="full"
                                        name="ownPercentage"
                                        formId="investmentSetupForm"
                                        placeholder="Select percentage"
                                        placeholderAsLabel="false"
                                        align="left"
                                        onOnSelect={event => dispatch(setOwnPercentageValue(event.detail.id))}
                                        value={increaseOwnAmount.value}
                                        state={increaseOwnAmount.status}
                                        errorMessage={increaseOwnAmount.error}
                                        options={
                                            JSON.stringify(
                                                referencePercentages && referencePercentages.map(premiumPercentage => ({
                                                    value: premiumPercentage.code,
                                                    label: premiumPercentage.description,
                                                }))
                                            )
                                        }
                                    >
                                    </OmFormDropdownFieldWrapper>
                                </div>
                            </OmApplicationRadioButtonAccordionItem>
                            <OmApplicationRadioButtonAccordionItem
                                onOnChange={() => dispatch(selectNoIncrease(), resetOwnPercentageValue())}
                                buttonGroupName="premiumEscalation"
                                radioButtonId="3"
                                radioButtonTitle="No increase"
                                description="Your contributions will not increase.">
                            </OmApplicationRadioButtonAccordionItem>
                        </div>
                    </OmApplicationRadioButtonAccordionGroup>
                    <OmFormError message={escalationError}></OmFormError>
                </section>
            }
            { yearlyIncreaseSectionCompleted &&
                <section className="fund-selection" id="typeOfInvestor">
                    <h5>What type of <strong>investor are you?</strong></h5>
                    <p>Some investors are comfortable, while others are adventurous depending on their level of risk tolerance.<br/><br/>
                        To guide us in allocating a suitable fund for your investment, select what type of investor you are.
                    </p>
                    <OmApplicationRadioButtonAccordionGroup
                        horizontal={true}
                        selectedValue={investorType}
                        onOnSelection={(event) => handleInvestorTypeSelection(event.detail)}
                    >
                        <div slot="radio-accordion-items">
                            <OmApplicationRadioButtonAccordionItemBold
                                buttonGroupName="fundSelection"
                                radioButtonId="1"
                                radioButtonTitle="Comfortable investor"
                                borderColour="blue">
                                <div slot="button-description">
                                    Suitable for investors who want moderate to <strong>high long-term growth</strong>, with fewer ups and downs.
                                </div>
                                <div slot="panel-content">
                                    <div className="panel-heading">
                                        <OmIcon imageUrl="https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/blt8a7755fc952f1e46/5ebd16e7bf460a7342d0e0a8/TreeHandSkyCerise.svg"/>
                                        <p className="panel-heading-text">The associated fund is <strong>Old Mutual Balanced Fund</strong></p>
                                    </div>

                                    <ul>
                                        <li>Suitable for medium to long-term investment</li>
                                        <li>Aimed to achieve long-term inflation-beating growth</li>
                                        <li>Invests more heavily in shares</li>
                                    </ul>
                                    <div className="panel-button-wrapper">
                                        <OmButton
                                            className="button"
                                            type="secondary"
                                            actionType="url"
                                            url={comfortableInvestorFactSheet}
                                            openTab={true}
                                            size="small"
                                            text="FUND FACT SHEET"
                                            onClick={() => dispatch(setFactSheetClicked('comfortableInvestor'))}
                                        />
                                    </div>
                                </div>
                            </OmApplicationRadioButtonAccordionItemBold>
                            <OmApplicationRadioButtonAccordionItemBold
                                buttonGroupName="fundSelection"
                                radioButtonId="2"
                                radioButtonTitle="Adventurous investor"
                                borderColour="heritageGreen">
                                <div slot="button-description">
                                    Suitable for investors who want moderate to <strong>high long-term growth</strong> and can tolerate more ups and downs.
                                </div>
                                <div slot="panel-content">
                                    <div className="panel-heading">
                                        <OmIcon imageUrl="https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/bltff7d5759df93afb7/5ebd16e7a467a375afdcd6a9/TreeHandFreshHeritage.svg"/>
                                        <p className="panel-heading-text">The associated fund is <strong>Old Mutual Edge 28 Life Fund</strong></p>
                                    </div>

                                    <ul>
                                        <li>Suitable for long-term investment</li>
                                        <li>Aimed at customers at the start or middle of their pre-retirement savings phase</li>
                                        <li>Invests primarily in growth assets</li>
                                    </ul>
                                    <div className="panel-button-wrapper">
                                        <OmButton
                                            className="button"
                                            type="secondary"
                                            actionType="url"
                                            url={adventurousInvestorFactSheet}
                                            openTab={true}
                                            size="small"
                                            text="FUND FACT SHEET"
                                            onClick={() => dispatch(setFactSheetClicked('adventurousInvestor'))}
                                        />
                                    </div>
                                </div>
                            </OmApplicationRadioButtonAccordionItemBold>
                        </div>
                    </OmApplicationRadioButtonAccordionGroup>
                    <OmFormError message={investorTypeError}/>
                </section>
            }
        </div>
    );
};

export default InvestmentSetupPage;

