import { createSelector } from "reselect";
import { getRaOptimal } from "./index";

export const getScrolling = createSelector(
    getRaOptimal,
    raOptimal => raOptimal.scrolling
);

export const getScrollToClass = createSelector(
    getScrolling,
    scrolling => scrolling.scrollToClass,
)

export const getScrollToId = createSelector(
    getScrolling,
    scrolling => scrolling.scrollToId,
)