import { OmApplicationBeneficiaryDetailsCard, OmApplicationBeneficiaryList } from '@om/component-library-react';
import React from 'react';

const ApplicationBeneficiaryDetailsList = ({
    beneficiaryList,
    editHandler,
    removeHandler,
}) => {
    return (
        <OmApplicationBeneficiaryList count={beneficiaryList.length} className="sessioncamhidetext">
            <div slot="beneficiary-cards">
                {beneficiaryList && beneficiaryList.map((beneficiary, index) => {
                    return (
                        <OmApplicationBeneficiaryDetailsCard
                            key={index}
                            number={index + 1}
                            name={beneficiary.value.firstName.value}
                            surname={beneficiary.value.surname.value}
                            onPopupModal={() => removeHandler(beneficiary.id)}
                            onEditBeneficiary={() => editHandler(beneficiary.id)}
                        />
                    )
                })}
            </div>
        </OmApplicationBeneficiaryList>
    );
};

export default ApplicationBeneficiaryDetailsList;