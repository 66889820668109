import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { navigate } from 'gatsby';

import EffectiveAnnualCost from './sections/EffectiveAnnualCost';
import FeesChargesBoosters from './sections/FeesChargesBoosters';
import ReplacementPolicyDrawer from './drawers/ReplacementPolicyDrawer';
import TermsAndConditionsDrawer from './drawers/TermsAndConditionsDrawer';

import { getName, getSurname, getConfirmPageMobileNumber } from '@om/redux/selectors/raOptimal/captureDetails';
import {
    getRetirementAge,
    getMonthlyInvestment,
    getMonthlyInvestmentDerivedTerm,
    getComfortableInvestorIsSelected,
    getYearlyPaymentIncrease,
    getInvestorTypeText,
    getInvestmentFund
} from '@om/redux/selectors/raOptimal/investmentSetup';
import {
    getTitle,
    getMiddleName,
    getEmail,
    getId,
    getMaritalStatus,
    getNationality,
    getIncomeTax,
    getCountryOfBirth,
    getPostalAddressSame,
    getAddressLabel,
    getDisplayResidentialAddress,
    getDisplayPostalAddress
} from '@om/redux/selectors/raOptimal/personalDetails';
import {
    getBankName,
    getAccountHolderName,
    getAccountNumber,
    getAccountType,
    getDebitOrderDay,
    getStartMonth
} from '@om/redux/selectors/raOptimal/paymentDetails';
import {
    getTermsAndConditionsValid,
    getReplacementPolicyValid,
    getReplacementPolicyError,
    getTermsAndConditionsError
} from '@om/redux/selectors/raOptimal/review';
import {
    toggleTermsAndConditions,
    toggleReplacementPolicy,
    setEditPageReviewTracking,
    setReplacementPolicyDrawer,
    setTermsAndConditionsDrawer,
} from '@om/redux/actions/raOptimal/review';
import { getBeneficiaryList } from '@om/redux/selectors/raOptimal/beneficiaries';
import { getSplitFieldArray } from '@om/redux/selectors/raOptimal/beneficiarySplit';
import {
    getReferenceTitles,
    getReferenceMaritalStatuses,
    getReferenceNationalities,
    getReferenceBanks,
    getReferenceBankAccountTypes,
} from '@om/redux/selectors/raOptimal/referenceData';
import { PAGES } from '@om/redux/actions/raOptimal/types';
import {
    OmAccordionItem,
    OmApplicationReviewSection,
    OmLabeledValueSummary,
    OmRaReview,
    OmCheckBox,
    OmFormError
} from '@om/component-library-react';

const ReviewPage = () => {
    const dispatch = useDispatch();
    const {
        tcsChecked,
        replacementPolicyChecked,

        //Investment setup section
        retirementAge,
        monthlyPayment,
        term,
        yearlyPaymentIncrease,
        investorType,
        investmentFund,
        comfortableInvestor,

        //Personal details section
        title,
        name,
        middleName,
        surname,
        maritalStatus,
        contactNumber,
        email,
        id,
        countryOfBirth,
        nationality,
        incomeTax,

        //Address section
        postalAddressSame,
        addressLabel,
        residentialAddress,
        postalAddress,

        // Beneficiaries
        beneficiaryList,
        splitFields,

        // Payment Details Section
        bankName,
        accountHolderName,
        accountNumber,
        accountType,
        debitOrderDay,
        startMonth,

        referenceTitles,
        referenceMaritalStatuses,
        referenceNationalities,
        referenceBanks,
        referenceBankAccountTypes,

        replacementPolicyError,
        termsAndConditionsError,
    } = useSelector(
        createStructuredSelector({
            tcsChecked: getTermsAndConditionsValid,
            replacementPolicyChecked: getReplacementPolicyValid,

            //Investment setup section
            retirementAge: getRetirementAge,
            monthlyPayment: getMonthlyInvestment,
            term: getMonthlyInvestmentDerivedTerm,
            comfortableInvestor: getComfortableInvestorIsSelected,
            yearlyPaymentIncrease: getYearlyPaymentIncrease,
            investorType: getInvestorTypeText,
            investmentFund: getInvestmentFund,

            //Personal details section
            title: getTitle,
            name: getName,
            middleName: getMiddleName,
            surname: getSurname,
            maritalStatus: getMaritalStatus,
            contactNumber: getConfirmPageMobileNumber,
            email: getEmail,
            id: getId,
            countryOfBirth: getCountryOfBirth,
            nationality: getNationality,
            incomeTax: getIncomeTax,

            //Address section
            postalAddressSame: getPostalAddressSame,
            addressLabel: getAddressLabel,
            residentialAddress: getDisplayResidentialAddress,
            postalAddress: getDisplayPostalAddress,

            // Beneficiaries
            beneficiaryList: getBeneficiaryList,
            splitFields: getSplitFieldArray,

            // Payment Details Section
            bankName: getBankName,
            accountHolderName: getAccountHolderName,
            accountNumber: getAccountNumber,
            accountType: getAccountType,
            debitOrderDay: getDebitOrderDay,
            startMonth: getStartMonth,

            // Reference fields
            referenceTitles: getReferenceTitles,
            referenceMaritalStatuses: getReferenceMaritalStatuses,
            referenceNationalities: getReferenceNationalities,
            referenceBanks: getReferenceBanks,
            referenceBankAccountTypes: getReferenceBankAccountTypes,

            replacementPolicyError: getReplacementPolicyError,
            termsAndConditionsError: getTermsAndConditionsError,
        })
    );

    const referenceDisplayValue = (referenceArray, value) => {
        if (referenceArray && value) {
            const data = referenceArray.find(data => data.code === value);
            return data.description;
        }
        return '';
    };
    const beneficiaryName = (beneficiary) => {
        return [beneficiary.value.firstName.value, beneficiary.value.surname.value].join(' ');
    };
    const routeToInvestmentSetup = () => {
        dispatch(setEditPageReviewTracking('investmentSetup'));
        navigate(PAGES.INVESTMENT_SETUP.FULL_URL);
    };
    const routeToPersonalDetails = () => {
        dispatch(setEditPageReviewTracking('personalDetails'));
        navigate(PAGES.PERSONAL_DETAILS.FULL_URL);
    };
    const routeToBeneficiaries = () => {
        dispatch(setEditPageReviewTracking('beneficiaries'));
        navigate(PAGES.BENEFICIARIES.FULL_URL);
    };
    const routeToPaymentDetails = () => {
        dispatch(setEditPageReviewTracking('paymentDetails'));
        navigate(PAGES.PAYMENT_DETAILS.FULL_URL);
    };

    const sideDrawerClosedHandler = () => {
        dispatch(setReplacementPolicyDrawer(false));
        dispatch(setTermsAndConditionsDrawer(false));
    };

    return (
        <OmRaReview onSideDrawerClosed={() => sideDrawerClosedHandler()}>
            <h4 className="page-heading">
                Lastly, please<strong> confirm your details</strong>
            </h4>

            <OmApplicationReviewSection titleHeader="Retirement Annuity Summary">
                <span slot="edit-link" onClick={() => routeToInvestmentSetup()} >
                    <h6 className="small"><strong>EDIT</strong></h6>
                </span>
                <div slot="section-body">
                    <OmLabeledValueSummary
                        label="Retirement age"
                        value={retirementAge.value}
                    />
                    <OmLabeledValueSummary
                        label="Monthly payment"
                        value={(monthlyPayment.value ? 'R' + monthlyPayment.value : ' -')}
                    />
                    <OmLabeledValueSummary
                        label="Payment term"
                        value={(term ? term  + ' years' : '-')}
                    />
                    <OmLabeledValueSummary
                        label="Yearly payment increase"
                        value={yearlyPaymentIncrease}
                    />
                    <OmLabeledValueSummary
                        label="Investor type"
                        value={investorType}
                    />
                    <OmLabeledValueSummary
                        label="Investment fund"
                        value={investmentFund}
                    />
                </div>
            </OmApplicationReviewSection>

            <OmApplicationReviewSection titleHeader="Personal Details">
                <span slot="edit-link" onClick={() => routeToPersonalDetails()} >
                    <h6 className="small"><strong>EDIT</strong></h6>
                </span>
                <div slot="section-body">
                    <OmLabeledValueSummary
                        label="Title"
                        value={referenceDisplayValue(referenceTitles, title.value)}
                    />
                    <OmLabeledValueSummary
                        isSensitive
                        label="Full name"
                        value={[name.value, middleName.value, surname.value].filter(Boolean).join(' ')}
                    />
                    <OmLabeledValueSummary
                        label="Marital status"
                        value={referenceDisplayValue(referenceMaritalStatuses, maritalStatus.value)}
                    />
                    <OmLabeledValueSummary
                        isSensitive
                        label="Contact number"
                        value={contactNumber}
                    />
                    <OmLabeledValueSummary
                        isSensitive
                        label="Email"
                        value={email.value}
                    />
                    <OmLabeledValueSummary
                        isSensitive
                        label="ID number"
                        value={id.value}
                    />
                    <OmLabeledValueSummary
                        label="Nationality"
                        value={referenceDisplayValue(referenceNationalities, nationality.value)}
                    />
                    <OmLabeledValueSummary
                        label="Country of birth"
                        value={referenceDisplayValue(referenceNationalities, countryOfBirth.value)}
                    />
                    { incomeTax.value &&
                        <OmLabeledValueSummary
                            isSensitive
                            label="Income tax number"
                            value={incomeTax.value}
                        />
                    }
                </div>
            </OmApplicationReviewSection>

            <OmApplicationReviewSection titleHeader="Address Details">
                <span slot="edit-link" onClick={() => routeToPersonalDetails()} >
                    <h6 className="small"><strong>EDIT</strong></h6>
                </span>
                <div slot="section-body">
                    <OmLabeledValueSummary
                        isSensitive
                        label={addressLabel}
                        value={residentialAddress}
                    />
                    { !postalAddressSame.value &&
                        <OmLabeledValueSummary
                            isSensitive
                            label="Postal address"
                            value={postalAddress}
                        />
                    }
                </div>
            </OmApplicationReviewSection>

            { beneficiaryList.length > 0 &&
                <OmApplicationReviewSection titleHeader="Beneficiaries Details">
                    <span slot="edit-link" onClick={() => routeToBeneficiaries()} >
                        <h6 className="small"><strong>EDIT</strong></h6>
                    </span>
                    { beneficiaryList.length > 1 ?
                        <div slot="section-body">
                            { beneficiaryList.map((beneficiary, index) => {
                                return (
                                    <div className="beneficiary-split-component" key={index}>
                                        <OmLabeledValueSummary
                                            isSensitive
                                            class="beneficiary-name"
                                            label="Full name"
                                            value={beneficiaryName(beneficiary)}
                                        />
                                        <OmLabeledValueSummary
                                            class="beneficiary-split"
                                            label="Split"
                                            value={splitFields[index].value + '%'}
                                        />
                                    </div>
                                )})
                            }
                        </div>
                        :
                        <div slot="section-body">
                            <OmLabeledValueSummary
                                isSensitive
                                class="beneficiary-name"
                                label="Full name"
                                value={beneficiaryName(beneficiaryList[0])}
                            />
                        </div>
                    }
                </OmApplicationReviewSection>
            }

            <OmApplicationReviewSection titleHeader="Payment Details">
                <span slot="edit-link" onClick={() => routeToPaymentDetails()} >
                    <h6 className="small"><strong>EDIT</strong></h6>
                </span>
                <div slot="section-body">
                    <OmLabeledValueSummary
                        label="Bank name"
                        value={referenceDisplayValue(referenceBanks, bankName.value)}
                    />
                    <OmLabeledValueSummary
                        isSensitive
                        label="Account holder name"
                        value={accountHolderName.value}
                    />
                    <OmLabeledValueSummary
                        isSensitive
                        label="Account number"
                        value={accountNumber.value}
                    />
                    <OmLabeledValueSummary
                        label="Account type"
                        value={referenceDisplayValue(referenceBankAccountTypes, accountType.value)}
                    />
                    <OmLabeledValueSummary
                        label="Debit order date"
                        value={debitOrderDay.value}
                    />
                    <OmLabeledValueSummary
                        label="Debit order starting month"
                        value={startMonth.value}
                    />
                </div>
            </OmApplicationReviewSection>

            <OmAccordionItem
                className="accordion-item"
                buttonText="Fees, Chargers and Boosters"
                displayMode="card"
                hasButtonSeparator
            >
                <div slot="panel-content">
                    <FeesChargesBoosters fund={comfortableInvestor ? 1 : 2}/>
                </div>
            </OmAccordionItem>
            <OmAccordionItem
                className="accordion-item"
                displayMode="card"
                buttonText="Effective Annual Cost"
                hasButtonSeparator >
                <div slot="panel-content">
                    <EffectiveAnnualCost />
                </div>
            </OmAccordionItem>

            <div className="check-box-container">
                <OmCheckBox
                    id="replacement-policy"
                    checked={replacementPolicyChecked}
                    onOnCheckBoxChanged={() => dispatch(toggleReplacementPolicy())}
                >
                    <p slot="text-slot">
                        I understand that replacing any investment or insurance policy with this investment may be to my disadvantage.&nbsp;
                        <button onClick={() => dispatch(setReplacementPolicyDrawer(true))}>
                            Read more.
                        </button>
                    </p>
                </OmCheckBox>
                <OmFormError message={replacementPolicyError}></OmFormError>
            </div>
            <div className="check-box-container">
                <OmCheckBox
                    id="terms-and-conditions"
                    checked={tcsChecked}
                    onOnCheckBoxChanged={() => dispatch(toggleTermsAndConditions())}
                >
                    <p slot="text-slot">
                        I accept the&nbsp;
                        <button onClick={() => dispatch(setTermsAndConditionsDrawer(true))}>
                            Old Mutual Terms &amp; Conditions.
                        </button>
                    </p>
                </OmCheckBox>
                <OmFormError message={termsAndConditionsError}></OmFormError>
            </div>


            <ReplacementPolicyDrawer />
            <TermsAndConditionsDrawer />
        </OmRaReview>
    );
};

export default ReviewPage;

