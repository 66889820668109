import { OmApplicationPopupModal, OmIcon } from '@om/component-library-react';
import OmButton from '../../../../components/forms/OmButton';
import React from 'react';

const ServerErrorResponseModal = ({ open, buttonHandler }) => {
    return (
        <OmApplicationPopupModal
            name="ErrorResponseModal"
            exitable="false"
            open={open}>
            <div slot="content">
                <OmIcon imageUrl="https://eu-images.contentstack.com/v3/assets/blt0554f48052bb4620/blt7379af7d0a25dd73/5ee9f203557e3a516a2d9a29/desktop_monitor_smiley.svg" />
                <h5 className="no-top-margin no-bottom-margin">
                    Oops!<br></br>
                    Something went wrong?
                </h5>
                <h6 className="no-bottom-margin account-verification-text">It's not you, it’s us. Not to worry, we will be in contact with you shortly to help you proceed with your application.</h6>
            </div>
            <div slot="button">
                <OmButton
                    type='primary'
                    size='large'
                    text='Okay, thanks'
                    onClick={() => buttonHandler()}
                />
            </div>
        </OmApplicationPopupModal>
    );
};

export default ServerErrorResponseModal;