import React from 'react';
import { OmApplicationPopupModal } from '@om/component-library-react';
import OmButton from '../../../../components/forms/OmButton';

const MaxAddedModal = () => {
    return (
        <OmApplicationPopupModal name="maxAdded" dismissible>
            <div slot="content">
                <p><strong>Unfortunately,</strong> you are only allowed to add up to 10 beneficiaries online.</p>
            </div>
            <div slot="button">
                <OmButton
                    type="primary"
                    size="small"
                    text="OKAY"
                />
            </div>
        </OmApplicationPopupModal>
    );
};

export default MaxAddedModal;
