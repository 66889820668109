import { createSelector } from "reselect";
import { getRaOptimal } from "./index";

export const getSessionCamVariables = createSelector(getRaOptimal, ra => ra.sessionCamVariables);

export const getRequestId = createSelector(
    getSessionCamVariables,
    sessionCamVariables => sessionCamVariables ? sessionCamVariables.requestId : ''
);

