import React from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getIncludeDropoffDetails } from '@om/redux/selectors/raOptimal/captureDetails';
import FourColumnIllustration from '../../../components/FourColumnIllustration';

const ShowBenefitsPage = ({ entry }) => {
    const {
        includeDropoffDetails
    } = useSelector(
        createStructuredSelector({
            includeDropoffDetails: getIncludeDropoffDetails
        })
    );

    return (
        <FourColumnIllustration 
            fourColumnLayout={[
                {
                    ...entry[0], 
                    button_url: {
                        href: includeDropoffDetails ? '/retirement-annuity/capture-details' : '/retirement-annuity/investment-setup'
                    }
                }
            ]} />
    );
};

export default ShowBenefitsPage;

