import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import { DEFAULT_VALIDATION_DELAY } from '../../../config';
import {
    OmFormDropdownFieldWrapper,
    OmFormInputFieldWrapper,
    OmFormError,
    OmCheckBox
} from '@om/component-library-react';

import {
    togglePaymentAuthorization,
    setBankNameValue,
    setAccountHolderNameValue,
    setAccountNumberValue,
    setAccountTypeValue,
    setDebitOrderDayValue,
    setStartMonthValue,
} from '@om/redux/actions/raOptimal/paymentDetails';
import {
    getPaymentAuthorizationValid,
    getPaymentAuthorizationInvalid,
    getBankName,
    getAccountHolderName,
    getAccountNumber,
    getAccountType,
    getDebitOrderDay,
    getStartMonth,
    getPaymentDetailsCompleted,
    getReferenceMonths,
    getReferenceDates
} from '@om/redux/selectors/raOptimal/paymentDetails';
import {
    getReferenceBanks,
    getReferenceBankAccountTypes
} from '@om/redux/selectors/raOptimal/referenceData';

const AccountDetailsSection = () => {
    const dispatch = useDispatch();
    const {
        paymentAuthorizationValid,
        paymentAuthorizationInvalid,

        bankName,
        accountHolderName,
        accountNumber,
        accountType,
        debitOrderDay,
        startMonth,
    
        referenceBanks,
        referenceBankAccountTypes,
        referenceMonths,
        referenceDates,
        paymentDetailsCompleted,
    } = useSelector(
        createStructuredSelector({
            paymentAuthorizationValid: getPaymentAuthorizationValid,
            paymentAuthorizationInvalid: getPaymentAuthorizationInvalid,

            bankName: getBankName,
            accountHolderName: getAccountHolderName,
            accountNumber: getAccountNumber,
            accountType: getAccountType,
            debitOrderDay: getDebitOrderDay,
            startMonth: getStartMonth,

            referenceBanks: getReferenceBanks,
            referenceBankAccountTypes: getReferenceBankAccountTypes,
            referenceMonths: getReferenceMonths,
            referenceDates: getReferenceDates,

            paymentDetailsCompleted: getPaymentDetailsCompleted,
        })
    );

    const updateAccountHolderNamePersist = (value) => {
        if (accountHolderName.value !== value) {
            dispatch(setAccountHolderNameValue(value));
        }
    };
    const updateAccountNumberPersist = (value) => {
        if (accountNumber.value !== value) {
            dispatch(setAccountNumberValue(value));
        }
    };

    return (
        <div>
            <h4 className="heading" id="account-details-anchor">
                Now, we'd like to know <strong>your payment details</strong>
            </h4>
            <p className="subheading">
                We need the following information to setup your contract.
            </p>
            <section className="form">
                <OmFormDropdownFieldWrapper
                    useNativeMobileDropdown
                    id="bankName"
                    required
                    form-id="paymentDetailsForm"
                    placeholder="Bank name"
                    name="bankName"
                    align="left"
                    value={bankName.value}
                    state={bankName.status}
                    errorMessage={bankName.error}
                    options={referenceBanks &&
                        JSON.stringify(referenceBanks.map(option => ({ value: option.code, label: option.description })))
                    }
                    onOnSelect={event => dispatch(setBankNameValue(event.detail.id)) }
                />
                <OmFormInputFieldWrapper
                    isSensitive
                    id="accountHolderName"
                    placeholder="Account holder name"
                    required
                    size="full"
                    form-id="paymentDetailsForm"
                    name="accountHolderName"
                    align="left"
                    validationDelay={DEFAULT_VALIDATION_DELAY}
                    value={accountHolderName.value}
                    state={accountHolderName.status}
                    errorMessage={accountHolderName.error}
                    onPersist={event => updateAccountHolderNamePersist(event.detail)}
                />
                <OmFormInputFieldWrapper
                    isSensitive
                    id="accountNumber"
                    placeholder="Account number"
                    required="true"
                    mask="number"
                    size="full"
                    form-id="paymentDetailsForm"
                    name="accountNumber"
                    align="left"
                    validationDelay={DEFAULT_VALIDATION_DELAY}
                    value={accountNumber.value}
                    state={accountNumber.status}
                    errorMessage={accountNumber.error}
                    onPersist={event => updateAccountNumberPersist(event.detail)}
                />
                <OmFormDropdownFieldWrapper
                    useNativeMobileDropdown
                    id="accountType"
                    required="true"
                    form-id="paymentDetailsForm"
                    placeholder="Account type"
                    name="accountType"
                    align="left"
                    value={accountType.value}
                    state={accountType.status}
                    errorMessage={accountType.error}
                    options={referenceBankAccountTypes &&
                        JSON.stringify(referenceBankAccountTypes.map(option => ({ value: option.code, label: option.description })))
                    }
                    onOnSelect={event => dispatch(setAccountTypeValue(event.detail.id)) }
                />
                <OmFormDropdownFieldWrapper
                    useNativeMobileDropdown
                    id="debitOrderDay"
                    required="true"
                    form-id="paymentDetailsForm"
                    placeholder="Select a day for your debit order"
                    placeholder-as-label="false"
                    label="Debit order date"
                    name="debitOrderDay"
                    align="left"
                    value={debitOrderDay.value}
                    state={debitOrderDay.status}
                    errorMessage={debitOrderDay.error}
                    options={referenceDates &&
                        JSON.stringify(referenceDates.map(option => ({ value: option.day, label: option.day })))
                    }
                    onOnSelect={event => dispatch(setDebitOrderDayValue(event.detail.id)) }
                />
                <OmFormDropdownFieldWrapper
                    disabled={referenceMonths.length > 0 ? false : true}
                    useNativeMobileDropdown
                    id="startMonth"
                    required
                    form-id="paymentDetailsForm"
                    placeholder="Select a start month"
                    placeholder-as-label="false"
                    label="Debit order starting month"
                    name="startMonth"
                    align="left"
                    value={startMonth.value}
                    state={startMonth.status}
                    errorMessage={startMonth.error}
                    options={referenceMonths &&
                        JSON.stringify(referenceMonths.map(option => ({ value: option, label: option })))
                    }
                    onOnSelect={event => dispatch(setStartMonthValue(event.detail.id)) }
                />
                <div className="check-box-container">
                    <OmCheckBox
                        disabled={!paymentDetailsCompleted}
                        text="I hereby authorise Old Mutual to withdraw all contributions that I undertake to pay towards my Old Mutual Invest Plan from this bank account. I also confirm (1) that the account details are correct and (2) that I am authorised to give this instruction."
                        checked={paymentAuthorizationValid}
                        onOnCheckBoxChanged={() => dispatch(togglePaymentAuthorization())}
                    />
                    <OmFormError message={paymentAuthorizationInvalid ? 'Authorization required to continue. ' : ''}></OmFormError>
                </div>

            </section>
        </div>
    );
};

export default AccountDetailsSection;

