import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DEFAULT_VALIDATION_DELAY } from '../../../config';
import {
    OmFormDropdownFieldWrapper,
    OmFormInputFieldWrapper,
    OmRaBeneficiaryDetails,
    OmSideDrawer,
    OmMobileInputFieldWrapper,
} from '@om/component-library-react';
import OmButton from '../../../../components/forms/OmButton';

import { getEditDrawerIsOpen } from '@om/redux/selectors/raOptimal/beneficiaries';
import {
    cancelEditOfBeneficiary,
    saveBeneficiary,
    setDateOfBirthValue,
    setEmailValue,
    setFirstNameValue,
    setGenderValue,
    setMobileValue,
    setSurnameValue,
    setTitleValue,
    toggleEditDrawer,
} from '@om/redux/actions/raOptimal/beneficiaries';
import {
    getEditBeneficiaryFormIsValid,
    getTitle,
    getFirstName,
    getSurname,
    getDateOfBirth,
    getGender,
    getMobileNumber,
    getEmail,
    getBeneficiaryEditId,
} from '@om/redux/selectors/raOptimal/beneficiaries';
import {
    getReferenceTitles,
    getReferenceGenders,
} from '@om/redux/selectors/raOptimal/referenceData';
import { FORM_STATUS } from '@om/redux/reducers/status';

const BeneficiaryDetailsDrawer = () => {
    const dispatch = useDispatch();
    const {
        open,
        editBeneficiaryFormIsValid,
        editId,
        title,
        firstName,
        surname,
        dateOfBirth,
        gender,
        mobileNumber,
        email,
        referenceTitles,
        referenceGenders,
    } = useSelector(
        createStructuredSelector({
            open: getEditDrawerIsOpen,
            editBeneficiaryFormIsValid: getEditBeneficiaryFormIsValid,
            editId: getBeneficiaryEditId,
            title: getTitle,
            firstName: getFirstName,
            surname: getSurname,
            dateOfBirth: getDateOfBirth,
            gender: getGender,
            mobileNumber: getMobileNumber,
            email: getEmail,
            referenceTitles: getReferenceTitles,
            referenceGenders: getReferenceGenders,
        })
    );

    const cancelAndCloseEditing = () => {
        dispatch(toggleEditDrawer());
        dispatch(cancelEditOfBeneficiary());
    };

    const saveAndCloseBeneficiary = () => {
        if (editBeneficiaryFormIsValid) {
            dispatch(toggleEditDrawer());
        }
        dispatch(saveBeneficiary(editId));
    };

    return (
        <OmSideDrawer onOnSideDrawerClosed={() => cancelAndCloseEditing()} open={open}>
            <div slot="side-drawer-content">
                <OmRaBeneficiaryDetails>
                    <div>
                        <h5 className="no-top-margin side-drawer-header">
                            {editId == null ? (
                                <strong> Add beneficiary details </strong>
                            ) : (
                                <strong> Edit beneficiary details </strong>
                            )}
                        </h5>
                        <OmFormDropdownFieldWrapper
                            useNativeMobileDropdown
                            required
                            className="inline-field"
                            size="small"
                            form-id="beneficiaryDetails"
                            placeholder="Title"
                            name="title"
                            align="left"
                            value={title.value}
                            state={title.status}
                            errorMessage={title.error}
                            options={
                                referenceTitles &&
                                JSON.stringify(
                                    referenceTitles.map((option) => ({
                                        value: option.code,
                                        label: option.description,
                                    }))
                                )
                            }
                            onOnSelect={(event) => dispatch(setTitleValue(event.detail.id))}
                        />
                        <OmFormInputFieldWrapper
                            isSensitive
                            placeholder="First name(s)"
                            required="true"
                            size="full"
                            form-id="beneficiaryDetails"
                            name="firstName"
                            validationDelay={DEFAULT_VALIDATION_DELAY}
                            value={firstName.value}
                            state={firstName.status}
                            errorMessage={firstName.error}
                            onPersist={(event) => dispatch(setFirstNameValue(event.detail))}
                        />
                        <OmFormInputFieldWrapper
                            isSensitive
                            placeholder="Surname"
                            required="true"
                            size="full"
                            form-id="beneficiaryDetails"
                            name="surname"
                            validationDelay={DEFAULT_VALIDATION_DELAY}
                            value={surname.value}
                            state={surname.status}
                            errorMessage={surname.error}
                            onPersist={(event) => dispatch(setSurnameValue(event.detail))}
                        />
                        <OmFormInputFieldWrapper
                            placeholder="Date of birth"
                            required
                            size="full"
                            form-id="beneficiaryDetails"
                            name="dateOfBirth"
                            mask="date"
                            keyboard-mode="numeric"
                            validationDelay={DEFAULT_VALIDATION_DELAY}
                            value={dateOfBirth.value}
                            state={dateOfBirth.status}
                            errorMessage={dateOfBirth.error}
                            onPersist={(event) => dispatch(setDateOfBirthValue(event.detail))}
                        />
                        <OmFormDropdownFieldWrapper
                            useNativeMobileDropdown
                            className="inline-field"
                            required
                            size="small"
                            form-id="beneficiaryDetails"
                            placeholder="Gender"
                            name="gender"
                            align="left"
                            value={gender.value}
                            state={gender.status}
                            errorMessage={gender.error}
                            options={
                                referenceGenders &&
                                JSON.stringify(
                                    referenceGenders.map((option) => ({
                                        value: option.code,
                                        label: option.description,
                                    }))
                                )
                            }
                            onOnSelect={(event) => dispatch(setGenderValue(event.detail.id))}
                        />

                        <h6 className="no-bottom-margin">
                            {' '}
                            Please supply at least one of the following:{' '}
                        </h6>
                        <div className="mobile-field">
                            <OmMobileInputFieldWrapper
                                validationDelay={DEFAULT_VALIDATION_DELAY}
                                value={mobileNumber.value}
                                state={mobileNumber.status}
                                errorMessage={mobileNumber.error}
                                onOnPersist={(event) => dispatch(setMobileValue(event.detail))}
                                hintText={
                                    mobileNumber.status === FORM_STATUS.VALID
                                        ? ''
                                        : 'Remember to add “0” in front of your mobile number. E.g. 082 123 4567'
                                }
                            />
                        </div>
                        <OmFormInputFieldWrapper
                            isSensitive
                            placeholder="Email"
                            required
                            size="full"
                            form-id="beneficiaryDetails"
                            name="email"
                            keyboard-mode="email"
                            validationDelay={DEFAULT_VALIDATION_DELAY}
                            value={email.value}
                            state={email.status}
                            errorMessage={email.error}
                            onPersist={(event) => dispatch(setEmailValue(event.detail))}
                        />
                    </div>

                    <div className="action-buttons">
                        <OmButton
                            className="cancel-button"
                            size="small"
                            type="text"
                            text="CANCEL"
                            onClick={() => cancelAndCloseEditing()}
                        />
                        <OmButton
                            className="save-button"
                            size="small"
                            type="primary"
                            text="SAVE BENEFICIARY"
                            onClick={() => saveAndCloseBeneficiary()}
                        />
                    </div>
                </OmRaBeneficiaryDetails>
            </div>
        </OmSideDrawer>
    );
};

export default BeneficiaryDetailsDrawer;
