import React from 'react';
import OmButton from '../../components/forms/OmButton';
import { navigate } from '@reach/router';
import {
    Om1ColLayout,
    OmIcon,
    OmApplicationWizardHeader,
    OmApplicationProgressBar,
    OmRaChatButton,
} from '@om/component-library-react';

const ApplicationWizardHeader = ({
    contactNumber,
    headerTextureUrl,
    title,
    includeRaLiveChat,
    isOfficeHours,
    closeRedirectUrl,
    progressBarIndex,
    displayProgressBar,
    applicationName,
    applicationSteps,
    hideDesktop,
    hideMobile,
    closeButtonhandler
}) => {
    return (
        <OmApplicationWizardHeader
            helpNumber={contactNumber}
            headerTexture={headerTextureUrl}
        >
            <h5 slot="wizard-title"><strong>{title}</strong></h5>

            <span slot="contact-button">
                { includeRaLiveChat &&
                    <OmRaChatButton url="https://secure.web.onecontact.1stream.co.za/clicktointeract_1/chat.aspx?oneContactInstance=omdfa" />
                }
            </span>

            <a slot="close-button" onClick={() => closeButtonhandler()}>
                <OmIcon className="close-icon" iconName="close" />
            </a>

            <div slot="progress-bar">
                <span className={`progress-bar-wrapper ${progressBarIndex < 0 ?  'intro-pages' : ''}`}>
                    <Om1ColLayout className="header">
                        { displayProgressBar &&
                            <OmApplicationProgressBar
                                applicationName={applicationName}
                                index={progressBarIndex}
                                steps={applicationSteps}
                                closable="true"
                                hideDesktop={hideDesktop}
                                hideMobile={hideMobile}
                            >
                                <OmButton
                                    actionType="url"
                                    slot="close-application"
                                    type="text"
                                    size="small"
                                    url="/"
                                    text="CLOSE"
                                />
                            </OmApplicationProgressBar>
                        }
                    </Om1ColLayout>
                </span>
            </div>
        </OmApplicationWizardHeader>
    );
};

export default ApplicationWizardHeader;