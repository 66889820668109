import {createSelector} from "reselect";
import {getRaOptimal} from "./index";

export const getRouting = createSelector(
    getRaOptimal,
    raOptimal => raOptimal.routing
);

// Drawers
export const getActivePageIndex = createSelector(
    getRouting,
    routing => routing.activePageIndex,
);